import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Colors } from '../../utils/Consts';
import TranslatedText from '../Language/TranslatedText';

interface IModalSearchBarProps {
    placeholder: string;
    value: string;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => createStyles({
    searchForm: {
        display: "flex",
        padding: "14px 0 14px 0",
    },
    searchBox: {
        height: 50,
        flexGrow: 1,
        border: "none",
        backgroundColor: Colors.ash,
        minHeight: 40,
        color: Colors.cyberspace,
        paddingLeft: 14,
        paddingRight: 14,
    },
    searchButton: {
        backgroundColor: Colors.ocean,
        color: "#ffffff",
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        height: 50,
        marginLeft: 14,
        textTransform: 'unset',
        fontFamily: "HK Grotesk Semibold,sans-serif",
        '&:hover': {
            backgroundColor: Colors.oceanLight,
        }
    },
    searchIcon: {
        width: 50,
        height: 50,
        marginRight: 14,
        color: "#000000",
    }
}))

export const ModalSearchBar: FC<IModalSearchBarProps> = (props: IModalSearchBarProps) => {
    const { placeholder, value, handleSubmit, handleChange } = props;
    const classes = useStyles();

    return (
        <Box>
            <form id="modal-whois-search-form" className={classes.searchForm} onSubmit={handleSubmit}>
                <SearchIcon className={classes.searchIcon}/>
                <input type="text" placeholder={placeholder}
                       name="whois searchbar"
                       id="modal-searchbar"
                       onChange={handleChange}
                       className={classes.searchBox}
                       value={value} />
                <Button aria-label="Search"
                            id="modal-search-button"
                            disableRipple
                            type="submit"
                            className={classes.searchButton}>
                    <TranslatedText textKey="Search" />
                </Button>
            </form>
        </Box>
    )
}

export default ModalSearchBar;
