import React, {FC, useContext } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

/** Context */
import { authContext } from '../../contexts/AuthContext';
import TranslatedText from "../../components/Language/TranslatedText";

const RootContainer: FC = () => {
  const { setUnauthStatus } = useContext(authContext);

  const logout = (): void => {
    setUnauthStatus();
   
    if (window.top) {
      window.top.location.href = "/";
    }
  };

  return (
    <Box>
      <Button variant="contained" color="secondary" onClick={logout}>
        <TranslatedText textKey="Sign out" />
      </Button>
    </Box>
  );
};

export default RootContainer;
