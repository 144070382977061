import { useContext, useEffect, useState } from 'react';

/** Custom types */
import { UserAuth } from '../../custom-types';

/** Utils */
import { DEFAULT_USER_AUTH } from '../Consts';
import {getCurrentUser, getDeskproToken, getRegistrarInfo} from '../Requests';

/** Context */
import { languageContext } from '../../contexts/LanguageContext';
import {UserResponse} from "../../custom-types/Request";

type AuthStatus = {
  auth: UserAuth;
  setAuthStatus: (userAuth: UserAuth) => void;
  setUnauthStatus: () => void;
}

const useAuthHandler = (initialState: UserAuth): AuthStatus => {
  const [auth, setAuth] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const lang = useContext(languageContext);

  const setUnauthStatus = (): void => {
    window.sessionStorage.removeItem('UserAuth');
    setAuth(DEFAULT_USER_AUTH);
  };

  const setAuthStatus = (userAuth: UserAuth): void => {
    window.sessionStorage.setItem('UserAuth', JSON.stringify(userAuth));
    setAuth(userAuth);

    if (!userAuth.registrarId) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchDataForUserWithToken(userAuth, true);
    }
  };

  const getDeskproLogin = (user: UserAuth): void => {
    const getDeskproTokenRequest = getDeskproToken(user.token);

    getDeskproTokenRequest.then((resp) => {
      resp.match({
        Ok: (okData) => {
          user.deskproToken = okData.value.token;
          setAuthStatus(user);
        },
        Err: () => setAuthStatus(user),
      })
    });
  };

  const fetchRegistrarForUser = (userData: UserResponse): Promise<{ur: UserResponse; org: string}> => {
    return getRegistrarInfo().then((resp) => {
      return resp.match({
        Ok: (okData) => {
          let userDisplayName = userData.username;
          if (okData.value) {
            userDisplayName = okData.value.org;
          }
          return {ur: userData, org: userDisplayName};
        },
        Err: () => {
          lang.displayErrorMessage('Could not fetch registrar. Please login again.');
          return {ur: userData, org: ''};
        }
      })
    })
  };

  /** Will fetch data for a user with a token. Used when logging in. */
  const fetchDataForUserWithToken = (authObj: UserAuth, gotoStart = false): void => {
    if (!authObj.token || !authObj.username) {
      return;
    }

    setLoading(true);
    const userRequest = getCurrentUser(authObj.token);
    userRequest.then((resp) => {

      return resp.match({
        Ok: (okData) => {
          const userData = okData.value;
          // Verify expected response properties
          const expectedProperties = ['username', 'role', 'registrar_id', 'tld_lid', 'tlds'];
          const hasExpectedProps = expectedProperties.every((prop) => {
            if (prop === 'tld_lid' && !(prop in userData) && 'tlds' in userData) return true;
            if (prop === 'tlds' && !(prop in userData) &&  'tld_lid' in userData) return true;
            return prop in userData
          });

          if (!userData || !hasExpectedProps || userData.role !== 'regweb_registrar') {
            lang.displayErrorMessage('Missing information about user. If this problem persists please contact support.');
            setUnauthStatus();
          }

          return {success: true, ur: userData};
        },
        Err: (errData) => {
          const bodyErrors = errData.err.errors[0];
          // eslint-disable-next-line max-len
          let errMsg = 'Encountered unknown error when trying to fetch user. If this problem persists please contact support.';

          if (bodyErrors.includes('expired')) {
            errMsg = 'Session expired. Please login again.';
          }

          lang.displayErrorMessage(errMsg);
          setUnauthStatus();

          return {success: false, ur: {} as UserResponse};
        }
      })
    }).then((userReq) => {
      if (!userReq.success) {
        return undefined
      }

      return fetchRegistrarForUser(userReq.ur);
    }).then((responseDatas) => {
      if (!responseDatas || !responseDatas.org) {
        setUnauthStatus();
        return;
      }
      const data = responseDatas.ur;
      const userDisplayName = responseDatas.org;
      if (data && data.role && data.role === 'regweb_registrar') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const userObj = {
          username: data.username,
          userDisplayName: userDisplayName,
          registrarId: data.registrar_id,
          token: authObj.token,
          role: data.role,
          deskproToken: '',
        };
        setAuthStatus(userObj);
        getDeskproLogin(userObj);
        if (gotoStart && window.location.pathname !== "/") {
          window.location.href = "/";
        }
      }
    }).catch(() => {
      lang.displayErrorMessage('Could not verify user. Please login again.');
      setUnauthStatus();
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    // Fetch JSON language file for default language.
    if (auth && auth.token && !loading) {
      fetchDataForUserWithToken(auth);
    }
    // eslint-disable-next-line
  }, []); // Empty deps so that it only runs once

  return {
    auth,
    setAuthStatus,
    setUnauthStatus,
  };
};
export default useAuthHandler;
