import { Colors } from './Consts';
import { CSSProperties } from '@mui/styles';

export const menuLink: CSSProperties = {
    fontFamily: "HK Grotesk Medium, sans-serif",
    fontSize: "18px",
    color: Colors.cyberspace,
    textTransform: 'none',
}

export const isCurrent: CSSProperties = {
    color: Colors.ruby
}

export const inputWrap: CSSProperties = {
    marginBottom: '1rem',
}

export const heading: CSSProperties = {
    fontFamily: "HK Grotesk Semibold, sans-serif",
    fontSize: "unset",
    fontWeight: "unset",
}

export const py0: CSSProperties = {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
}

export const px0: CSSProperties = {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
}

export const WhoisClasses = {
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "auto",
        borderBottom: "1px solid black",
        '&:nth-child(even)': {
            backgroundColor: Colors.ash,
        },
    },
    whoisItem: {
        padding: 4,
    },
    descriptor: {
        gridColumnStart: 1,
        fontWeight: "bold",
    },
    value: {
        textAlign: "right",
    },
    sublistItem: {
        padding: 0,
        magin: 0,
        fontSize: "0.7rem",
    }
}
