import React, {FC, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Collapse, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as ArrowDown } from '@internetstiftelsen/styleguide/src/atoms/icon/arrow-down.svg';

/** Utils */
import { menuLink, isCurrent } from '../../utils/Classes';
import PropTypes from "prop-types";


const useStyles = makeStyles({
  arrowUp: {
    transform: 'rotateX(180deg)',
  },
  iconArrow: {
    width: '1.1rem',
    height: '1.1rem',
    marginTop: '.4rem',
  },
  nested: {
    paddingLeft: '32px',
  },
  noPadding: {
    padding: '0',
  },
  listContainer: {
    width: '100%',
  },
  menuLink,
  isCurrent
});


interface IArrowMenuListInterface {
  headerIcon?: React.ReactElement;
  headerText: string | null;
  children: React.ReactNode;
}

const ArrowMenuList: FC<IArrowMenuListInterface> = ({children, headerText, headerIcon}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const arrowUpClass = clsx({
    [classes.iconArrow]: true,
    [classes.arrowUp]: true,
  });

  const expandListItem = (): void => {
    setOpen(!open);
  };

  return (
    <div className={classes.listContainer} onClick={(event): void => {event.stopPropagation(); expandListItem()}}>
      <ListItem button className={classes.noPadding}>
        <ListItemIcon>
          {headerIcon}
        </ListItemIcon>
        <ListItemText primary={headerText}
                      disableTypography={true}
                      className={classes.menuLink} />
        {open ? <ArrowDown className={arrowUpClass} /> : <ArrowDown className={classes.iconArrow} />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </div>
  );
};

ArrowMenuList.propTypes = {
  children: PropTypes.node,
  headerText: PropTypes.string,
  headerIcon: PropTypes.element,
};

export default ArrowMenuList;
