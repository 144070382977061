import React, {FC, useContext} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TranslatedText from '../../components/Language/TranslatedText';
import RouteTitle from "../../components/RouteTitle";
import {languageContext} from "../../contexts/LanguageContext";
import Card from "../../components/Card";
import { format, toDate } from 'date-fns'
import PixelHeart from '../../images/pixel-heart.jpg';
import Spinner from "../../components/Spinner";
import Alert from "../../components/alert/Alert";
import useNewsArticles from "../../utils/custom-hooks/newsArticles";

const useStyles = makeStyles((theme) => ({
	main: {
		width: '100%',
	},
	whiteSection: {
		backgroundColor: '#fff',

		'& .m-card--shadow': {
			boxShadow: '0 1rem 2rem rgba(31, 42, 54, 0.2)',
		}
	},
	applyCard: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: '2rem',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem',
		}
	},
	introText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	newsCard: {
		'& .m-card__content': {
			marginTop: 0,
			flexBasis: '60%',
			overflow: 'hidden',
		},
		'& .m-card__image': {
			maxWidth: '40%',
		}
	},
	spinner: {
		width: 200,
		height: 200,
		margin: "0 auto",
	},
}));

interface ISectionProps {
	children: React.ReactNode;
	className?: string;
}

const Section: FC<ISectionProps> = (props: ISectionProps) => {
	const { children, className } = props;
	const sectionClass = className ? className : '';
	return (
		<section className={sectionClass}>
			<div className="wrapper">
				{children}
			</div>
		</section>
	)
};

const Landing: FC = () => {
	const classes = useStyles();
	const lang = useContext(languageContext);
	const {newsArticles, loadingNews, newsError} = useNewsArticles(true);

	/*eslint-disable max-len*/
	const translations = {
		title: 'Welcome to the Registrar web',
		titleDescription: "This website provides information for The Swedish Internet Foundation's registrars. The website also serves as a tool for managing your registrar account after you have logged in.",
		section3p1: 'The Swedish Internet Foundation is an independent, private foundation that works for the positive development of the internet.',
		section3p2: 'We are a foundation and our charter states that we shall ensure a strong and secure infrastructure for the internet in Sweden, as well as enabling people to use the internet in the best way. Therefore, we offer digital education and knowledge that gives a greater understanding of the impact of digitalization on individuals and society. We shall also promote research, innovation and education with a focus on the internet and our goal is to invest at least 25 percent of our turnover into different internet-developing projects',
		section3p3: 'We are responsible for the Swedish top-level domain .se and the operation of the top-level domain .nu, and our vision is that everyone in Sweden wants to, dares to and is able to use the internet.',
	};
	/*eslint-enable max-len*/

	const displayNews = (
		newsArticles.map((n, index, list) => (
			<div key={index} className={`${index === 0 && list.length > 1 ? 'u-m-b-2' : ''}`}>
				<Card className={`m-card--shadow m-card--wide ${classes.newsCard}`} component="article" aboveContent={
					<img src={n.imageSrc} className="m-card__image" alt="for article" />
				}>
					<>
						<div className="m-card__meta">
							<time dateTime={format(toDate(n.publicationDate), 'yyyy-MM-dd')}
										className="a-meta">
								{format(toDate(n.publicationDate), 'd MMM, yyyy')}
							</time>
						</div>
						<a className="m-card__link" href={n.link}><h2>{n.title}</h2></a>
						<p className={`m-card__text ${classes.introText}`}>{n.description}</p>
					</>
				</Card>
			</div>
		))
	);

	const newsResult = (): React.ReactNode => {
		if (newsError) {
			return <Alert type='error' message={newsError} title={''} hidden={false}/>
		}

		return displayNews;
	};

  return (
    <main className={classes.main}>
			<Section>
				<RouteTitle title={lang.getTranslatedText(translations.title)}
										description={lang.getTranslatedText(translations.titleDescription)}
										extendMaxWidth={true} />
			</Section>

			<Section className={`u-p-y-5 ${classes.whiteSection}`}>
				<div className="row">
					<div className="grid-md-12 grid-18">
						{loadingNews ? <Spinner className={classes.spinner}/> : newsResult()}
					</div>

					<div className={`grid-md-6 grid-18 ${classes.applyCard}`}>
						<Card className="m-card--teaser" component="article"
									aboveContent={
										<img src={PixelHeart} className="m-card__image" alt="Internetstiftelsen apply" />
									}>

							<h1 className="alpha">{lang.getTranslatedText('Become a registrar')}</h1>
							<p className="m-card__text">
								{lang.getTranslatedText('Here you can apply to become a registrar')}
							</p>
							<div className="m-card__bottom">
								<a href="/apply" className="a-button a-button--lemon">
									<TranslatedText textKey={'Apply'} className={'a-button__text'} />
								</a>
							</div>
						</Card>
					</div>
				</div>
			</Section>

			<Section className={'u-m-t-4'}>
				<Card className="m-card--shadow" component="article" >
					<div className="row">
						<div className="grid-18">
							<h2 className="alpha">{lang.getTranslatedText('We are The Swedish Internet Foundation')}</h2>
							<p className="m-card__text">
								{lang.getTranslatedText(translations.section3p1)}
							</p>
							<p className="m-card__text">
								{lang.getTranslatedText(translations.section3p2)}
							</p>
							<p className="m-card__text">
								{lang.getTranslatedText(translations.section3p3)}
							</p>
						</div>
					</div>
				</Card>
			</Section>
    </main>
  );
};

export default Landing;
