export const CONSENT_COOKIE_NAME = 'internetstiftelsen-consent-cookie';

export enum CookieItemTypes {
  FirstParty = "1st party",
  ThirdParty = "3rd party",
}

export enum CoookieConsentCategories {
  Analytics = "Analytics",
  Functional = "Functional",
  Mandatory = "Mandatory",
}

export enum FunctionalityCookies {
  LanguageSelect,
  CookieConsent,
  CardPayment
}

export interface IFunctionsUsingCookies {
  accepted: boolean;
  func: FunctionalityCookies;
  cookieItems: ICookieConsentItem[];
  domain: string;
  type: CookieItemTypes;
}

interface ICookieConsentItem {
  description: string;
  expiry: string;
  ID: number;
  name: string;
}

export interface IConsentCategory {
  category: CoookieConsentCategories;
  consentGiven: boolean;
  functionality: IFunctionsUsingCookies[];
  description: string;
  header: string;
  ID: number;
}

/**
* Value for cookie can be something like:
* "F1&F3" which indicates that FunctionalityCookies 1 & 3 have been accepted.
* @param consentCategories
* @param acceptAll
*/
export const cookieConsentValuesString = (consentCategories: IConsentCategory[], acceptAll: boolean): string => {
  const consentGivenFor: string[] = [];
  consentCategories.forEach((c) => {
    c.functionality.forEach((f) => {
      if (acceptAll || c.consentGiven || f.accepted) {
        consentGivenFor.push(`F${f.func}`);
      }
    })
  })
  
  return consentGivenFor.join('&');
};

// Will remove consent cookie by setting expire date.
export const prepareRemoveConsentCookie = (): string => {
  return `${CONSENT_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=strict;Secure`;
};

// Remove will remove a cookie from all paths.
const removeCookieForName = (name: string): void => {
  // This function will attempt to remove a cookie from all paths.
  const pathBits = window.location.pathname.split('/');
  let pathCurrent = ' path=';
  
  // Simple pathless first.
  document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT;`;
  
  for (let i = 0; i < pathBits.length; i++) {
    pathCurrent += ((pathCurrent.substring(-1) != '/') ? '/' : '') + pathBits[i];
    document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT;${pathCurrent};`;
  }
};

// Will remove cookie by setting expire date.
// Parses document cookies and compares with cookie for given consent (CONSENT_COOKIE_NAME).
export const clearOldCookies = (): void => {
  const documentCookies = document.cookie.split(";");
  const savedCookieValue = getConsentCookieValues();
  
  documentCookies.forEach((dc) => {
    const eqPos = dc.indexOf("=");
    const dcName = eqPos > -1 ? dc.substring(0, eqPos).trimStart() : dc;
    
    const ignoreCookies = [CONSENT_COOKIE_NAME, 'Cookies are enabled']
    
    if (ignoreCookies.includes(dcName)) return;
    
    const removeCookie = ALL_COOKIES.some((cookieCategory) => {
      const foundCategory = cookieCategory.functionality.find((f) => {
        return f.cookieItems.some((i) => i.name === dcName)
      })
      
      if (!foundCategory) {
        return true;
      }
      
      if (isConsentGivenFor(foundCategory.func, savedCookieValue)) {
        return false
      }
      
      return true;
    });
    
    if (removeCookie) {
      removeCookieForName(dcName)
    }
  });
};

export const prepareCookieForSaving = (consentValues: string): string => {
  const d = new Date();
  const domain = window.location.hostname;
  const fixed = 'path=/;SameSite=strict;Secure';
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 365);
  return `${CONSENT_COOKIE_NAME}=${consentValues};domain=.${domain};expires=${d.toUTCString()};${fixed}`;
};

export const getConsentCookieValues = (): string => {
  const allCookies = document.cookie.split(';')
  const savedCookie = allCookies.find((c) => {
    const [k,v] = c.split('=');
    if (k.trim() === CONSENT_COOKIE_NAME) {
      return v;
    }
  })?.split('=')
  
  return savedCookie?.length ? savedCookie[1] : '';
};

export const isConsentGivenFor = (functionality: FunctionalityCookies, forCookieValue?: string): boolean => {
  const consentCookieValue = forCookieValue? forCookieValue : getConsentCookieValues();
  
  if (consentCookieValue) {
    return consentCookieValue.split('&').some((consentCookies) => {
      return consentCookies === `F${functionality}`;
    })
  }
  
  return false;
};

/* eslint-disable max-len */
export const ALL_COOKIES: IConsentCategory[] = [
  {
    category: CoookieConsentCategories.Functional,
    consentGiven: false,
    description: 'Functional cookies enable the website to provide enhanced functionality. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies, then some or all of these services may not function properly.',
    functionality: [
      {
        accepted: false,
        func: FunctionalityCookies.LanguageSelect,
        cookieItems: [
          {
            description: "This is used to remember the user's selected language if chosen.",
            expiry: 'HTML Local Storage',
            ID: 1,
            name: 'userlang',
          },
        ],
        domain: 'registry.se',
        type: CookieItemTypes.FirstParty
      },
      {
        accepted: false,
        func: FunctionalityCookies.CardPayment,
        cookieItems: [
          {
            description: 'Incapsula DDoS Protection and Web Application Firewall: The cookie which HTTP requests are related to a certain session. Re-opening the browser and accessing same site is supposed to be considered different visits.',
            expiry: 'Session',
            ID: 1,
            name: 'incap_ses_*',
          },
          {
            description: 'Incapsula DDoS Protection and Web Application Firewall: The cookie which sessions are related to a specific visitor (visitor representing a specific computer) in order to identify clients which have already visited Incapsula. The only cookie that is persistent, for the duration of 12 months.',
            expiry: '365 days',
            ID: 2,
            name: 'visid_incap_*',
          },
          {
            description: "Nets Easy's checkout uses a cookie to identify which device you are using. The identification is needed to be able to provide the user-requested feature Save my Device in the Service.",
            expiry: 'Permanent',
            ID: 3,
            name: 'DeviceTag',
          },
        ],
        domain: '.dibspayment.eu',
        type: CookieItemTypes.ThirdParty
      }
    ],
    header: 'Functional cookies',
    ID: 1,
  },
  {
    category: CoookieConsentCategories.Mandatory,
    consentGiven: true,
    description: 'Striclty necessary cookies are required for the website to work. These can be features that allow you to fill out forms, settings for your personal preferences or login. In your browser, you can choose to block or set so that you are warned about these cookies, but keep in mind that all or parts of the website will not work then. Strictly necessary cookies do not store any personally identifiable information.',
    functionality: [
      {
        accepted: true,
        func: FunctionalityCookies.CookieConsent,
        cookieItems: [
          {
            description: 'Den här kakan sätts för att testa om kakor är påslaget i besökarens webbläsare.',
            expiry: '1 år',
            ID: 1,
            name: 'Cookies are enabled',
          },
          {
            description: 'Den här kakan används för att spara information om vilka kakor användare har gett tillåtelse att använda',
            expiry: '1 år',
            ID: 2,
            name: 'internetstiftelsen-consent-cookie',
          },
          {
            description: 'Innehåller information för inloggad användare',
            expiry: 'HTML Session Storage',
            ID: 3,
            name: 'UserAuth',
          },
          {
            description: 'Cachar information som används för att rita ut grafer i statistikvyn.',
            expiry: 'HTML Session Storage',
            ID: 4,
            name: 'GeneralStatsTypes',
          },
          {
            description: 'Cachar information som används för att rita ut grafer i statistikvyn.',
            expiry: 'HTML Session Storage',
            ID: 5,
            name: 'RegistrarStatsTypes',
          },
        ],
        domain: 'registry.se',
        type: CookieItemTypes.FirstParty
      }
    ],
    header: 'Strictly necessary cookies',
    ID: 3,
  }
];
/* eslint-enable max-len */