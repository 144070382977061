import React, { FC, useContext } from 'react';

/** Context */
import { languageContext } from '../../contexts/LanguageContext';

/** Utils */
import { getTextForLanguage } from '../../utils/Language';

interface ITextKey {
  textKey: string;
  className?: string;
}

const TranslatedText: FC<ITextKey> = (props: ITextKey) => {
  const lang = useContext(languageContext);
  const { textKey, className } = props;

  const tk: string = (textKey === null) ? "" : textKey;

  const getMarkdownTranslation = (): string => {
    const translatedText: string = getTextForLanguage(lang.language, tk, lang.languageTranslations);
    return translatedText;
  };

  return (
    <span className={className}>{getMarkdownTranslation()}</span>
  );
};

export default TranslatedText;
