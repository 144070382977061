import React, { FC, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import { IconButton, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import punycode from 'punycode';

import { Colors } from '../../utils/Consts';
import { getWhois } from '../../utils/Requests';
import {WhoisResponse, WhoisContactResponse, WhoisDomainResponse} from '../../custom-types/Request';
import ModalWrapper from '../../components/Modal/ModalWrapper';
import ModalSearchBar from '../../components/Modal/ModalSearchBar';
import WhoisDataList from '../whois/WhoisDataList';
import TranslatedText from '../../components/Language/TranslatedText';
import { languageContext } from '../../contexts/LanguageContext';
import Spinner from '../../components/Spinner';

const useStyles = makeStyles(() => createStyles({
    container: {
        marginRight: 7,
        alignItems: "center",
        justifySelf: "end",
        display: "grid",
    },
    searchbox: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto 50px",
        gridTemplateRows: "50px",
        justifyItems: "end",
        alignItems: "center",
    },
    searchboxInput: {
        gridColumnStart: 1,
        gridRowStart: 1,
        background: "#666666",
        width: "0%",
        margin: 0,
        padding: 0,
        border: "none",
        borderRadius: 5,
        transition: "width .4s",
        zIndex: 11,
        color: "#ffffff",
        '&::placeholder': {
            color: "#ffffff",
        },
    },
    searchboxOpen: {
        width: "100%",
        padding: 7,
    },
    searchButton: {
        gridColumnStart: 2,
        gridRowStart: 1,
        width: 50,
        height: 50,
        padding: 0,
        margin: 0,
        color: "#fff",
        borderRadius: 0,
        "&:hover": {
            backgroundColor: Colors.cyberspace,
        },
    },
    closedText: {
        gridColumnStart: 1,
        gridRowStart: 1,
        color: "#ffffff",
        cursor: "text",
        zIndex: 10,
        textTransform: "unset",
    },
    modal: {
        color: "#000000",
    },
    closeButton: {
        backgroundColor: Colors.lemon,
        '&:hover': {
            backgroundColor: Colors.lemonLight,
            color: Colors.lemonLight,
        },
    },
    closeButtonText: {
        color: "#000000",
        border: "none",
        '&:hover': {
            color: "#000000",
        },
    },
    searchTitle: {
        color: "#000000",
    },
    hidden: {
        display: "none",
    },
    spinner: {
        width: 200,
        height: 200,
        margin: "0 auto",
    }
}));

export const WhoisSearchBar: FC = () => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [searchModalClosed, setSearchModalClosed] = useState(true);
    const [whoisDomain, setWhoisDomain] = useState("");
    const [whoisData, setWhoisData] = useState<WhoisResponse>();
    const [additionalData, setAdditionalData] = useState<WhoisContactResponse>();
    const [resultFound, setResultFound] = useState(false);
    const [tld, setTld] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const lang = useContext(languageContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (!isOpen) {
            event.preventDefault();
            setIsOpen(true)
            const input = document.getElementById('whois-searchbar');
            input?.focus();
        }

        return;
    };

    const whoisSearch = (searchText: string): void => {
        if (searchText === '') return;

        const parts = searchText.split('.');
        let tld = '';
        setWhoisDomain(searchText);
        setIsLoading(true);

        if (parts.length > 1) {  // This is a domain search
            tld = parts[parts.length - 1];

            let IDNADomain = '';
            let isIDNA = false;

            for (let i=0; i < parts.length - 1; i++) {  //IDNA encode each domain and sub domain
                if (IDNADomain === '') {
                    IDNADomain = punycode.toASCII(parts[i]);
                } else {
                    IDNADomain = `${IDNADomain}.${punycode.toASCII(parts[i])}`;
                }
                if (parts[i] !== punycode.toASCII(parts[i])) {
                    isIDNA = true;
                }
            }
            IDNADomain = `${IDNADomain}.${tld}`;
            getWhois(IDNADomain, tld).then((resp) => {
                resp.match({
                    Ok: (okData) => {
                        const domainData = okData.value as WhoisDomainResponse;
                        if (isIDNA) {
                            domainData.domain = `${domainData.domain} (${searchText})`;
                        }
                        setWhoisData(domainData);
                        setTld(tld);
                        setResultFound(true);
                        setIsLoading(false);
                    },
                    Err: () => {
                        setWhoisData(undefined);
                        setResultFound(false);
                        setIsLoading(false);
                    },
                })
            })
        } else { // This is a contactID and not a domain
            // This is ugly code, BAD code. _But_ this solves the rendering bug.
            // There is a better way, a cleaner way, I'm sorry to say I could not find
            // that way.
            const seReq = getWhois(searchText, 'se').then((resp): boolean => {
                resp.match({
                    Ok: (okData) => {
                        const contactData = okData.value as WhoisContactResponse;
                        setWhoisData(contactData);
                    },
                    Err: () => {
                        setWhoisData(undefined);
                    },
                });

                return resp.isOk()
            });
            const nuReq = getWhois(searchText, 'nu').then((resp): boolean => {
                resp.match({
                    Ok: (okData) => {
                        const contactData = okData.value as WhoisContactResponse;
                        setAdditionalData(contactData);
                    },
                    Err: () => {
                        setAdditionalData(undefined);
                    },
                });

                return resp.isOk()
            });

            Promise.all([seReq, nuReq]).then(([seSuccess, nuSuccess]) => {
                console.log("all are loaded", seSuccess, nuSuccess);
                const gotResult = seSuccess || nuSuccess;
                setResultFound(gotResult);
                setIsLoading(false);
            });
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (!isOpen && searchModalClosed) return setIsOpen(true);

        whoisSearch(whoisDomain)

        if (searchModalClosed) setSearchModalClosed(false);

        setIsOpen(false);
    };

    const handleModalClose = (): void => {
        setWhoisDomain("");
        setSearchModalClosed(true);
        setWhoisData(undefined);
        setResultFound(false);
    }

    const closeButton = (<button className={`a-button a-button--lemon ${classes.closeButton}`}
                                onClick={handleModalClose}>
                                    <span className={classes.closeButtonText}>{lang.getTranslatedText('Close')}</span>
                        </button>)

    return (
        <Box className={classes.container}>
            <form id='whois-search-form' className={`${classes.searchbox} `}
                  onSubmit={handleSubmit}>
                <input type="text" placeholder={lang.getTranslatedText("Search whois")}
                       name="whois searchbar"
                       id="whois-searchbar"
                       value={whoisDomain}
                       onChange={(e): void => setWhoisDomain(e.target.value)}
                       className={`${classes.searchboxInput} ${clsx(isOpen && classes.searchboxOpen)}`} />
                <Button className={`${classes.closedText} ${clsx(isOpen && classes.hidden)}`}
                        disableRipple
                        onClick={handleClick}>
                    <TranslatedText textKey="Search whois" />
                </Button>
                <IconButton
                    aria-label="Search Whois"
                    id="whois-search-button"
                    disableRipple
                    className={classes.searchButton}
                    onClick={handleClick}
                    type="submit"
                    size="large">
                    <SearchIcon />
                </IconButton>
            </form>
            <ModalWrapper closed={searchModalClosed}
                          hasForm={false}
                          modalTitle={lang.getTranslatedText("Search whois")}
                          modalInfo={lang.getTranslatedText("Whois info")}
                          footerButtons={closeButton}
                          onClose={handleModalClose}>
                <Typography variant="body1">
                    <a href="/whois-terms">
                        {lang.getTranslatedText("Here")}
                    </a> {lang.getTranslatedText("you can read terms and descriptions for Whois searches")}
                </Typography>
                <ModalSearchBar placeholder={lang.getTranslatedText("Search whois")}
                                handleChange={(e): void => setWhoisDomain(e.target.value)}
                                value={whoisDomain}
                                handleSubmit={handleSubmit} />
                {isLoading ? <Spinner className={classes.spinner} /> :
                             <WhoisDataList tld={tld}
                                            resultFound={resultFound}
                                            data={whoisData}
                                            additionalData={additionalData}
                                            updateFunction={whoisSearch} />}
            </ModalWrapper>
        </Box>
    );
}

export default WhoisSearchBar;
