import React, {FC} from 'react';
import { AlertLevel } from '../../custom-types';

interface IAlertProps {
    type: AlertLevel;
    message: string;
    title: string;
    hidden: boolean;
    extraClasses?: string;
    children?: React.ReactNode;
}

export const Alert: FC<IAlertProps> = (props: IAlertProps) => {
    const {type, message, title, hidden, extraClasses, children} = props;
    const hiddenClass = hidden ? 'hidden' : '';
    const additionalClass = extraClasses? extraClasses : '';
    const classname = `m-alert m-alert--${type} ${hiddenClass} ${additionalClass}`;
    return (
        <div role="alert" className={classname}>
            {title && <h2 className="u-m-b-1">{title}</h2>}
            {!children ?
              <p>
                  {message}
              </p> :
              children
            }

        </div>
    )
}

export default Alert;
