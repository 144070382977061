import React, {FC, createContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  CONSENT_COOKIE_NAME,
  FunctionalityCookies,
  clearOldCookies,
  isConsentGivenFor,
  prepareRemoveConsentCookie
} from "../utils/Cookies";

interface ICookieContextInterface {
  shouldShowCookieAlert: boolean;
  hideCookieAlert: () => void;
}

const cookieContext = createContext<ICookieContextInterface>({
  shouldShowCookieAlert: false,
  hideCookieAlert: (): void => { return },
});

export const COOKIE_VERSION = 'V1';

const { Provider } = cookieContext;
const CookieProvider: FC<{ children: React.ReactNode }> = ({
                                                           children,
                                                         }) => {
  const [shouldShowCookieAlert, setShouldShowCookieAlert] = useState(false);

  // Attempt to set cookie.
  const cookiesEnabled = (): boolean => {
    document.cookie = `Cookies are enabled=Yes${COOKIE_VERSION};path=/;SameSite=Strict;Secure;`;
    return document.cookie.indexOf('Cookies are enabled') !== -1;
  }

  const cookieExists = (name: string): boolean => {
    return document.cookie.indexOf(name) !== -1;
  }

  const hideCookieAlert = (): void => {
    setShouldShowCookieAlert(false)
  }

  useEffect(() => {
    // Always clear old cookies.
    // Remove consent cookie (gives user chance to select consent again) if changes
    // to available cookies have occured (@COOKIE_VERSION).
    if (cookieExists('Cookies are enabled') || cookieExists(CONSENT_COOKIE_NAME)) {
      const usingLatestCookieVersion = document.cookie.indexOf(`Cookies are enabled=Yes${COOKIE_VERSION}`) !== -1;

      if (!usingLatestCookieVersion) {
        const removeCookie = prepareRemoveConsentCookie()

        // Remove existing consent cookie.
        if (removeCookie) {
          document.cookie = removeCookie;
        }
      }

      // Remove any unused cookies.
      clearOldCookies()
    }

    if (cookiesEnabled() &&
      !(cookieExists(CONSENT_COOKIE_NAME) && isConsentGivenFor(FunctionalityCookies.CookieConsent))) {
      setShouldShowCookieAlert(true);
    }
  }, []);

  return (
    <Provider value={{ shouldShowCookieAlert, hideCookieAlert }}>
      {children}
    </Provider>
  );
};

CookieProvider.propTypes = {
  children: PropTypes.node
}

export { CookieProvider as default, cookieContext };
