import React, {FC, useState, useEffect, useContext} from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QRCode from 'qrcode.react';
import { green } from '@mui/material/colors';


/** Presentation */
import TranslatedText from '../../components/Language/TranslatedText';
import IISInput from "../../components/input/IISInput";
import PasswordInput from '../../components/input/PasswordInput';
import { inputWrap } from '../../utils/Classes';

/** Context */
import { authContext } from '../../contexts/AuthContext';
import {languageContext} from "../../contexts/LanguageContext";

/** Utils */
import {totpLogin, login, generateTotp} from '../../utils/Requests';
import {ReactComponent as LoadingIcon} from "../../images/icon-spinner.svg";
import ModalWrapper from "../../components/Modal/ModalWrapper";
import clsx from "clsx";

const useStyles = makeStyles({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '8px',
  },
  loginBtnProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  submit: {
    margin: '24px 0 16px',
  },
  qrCodeWrapper: {
    textAlign: 'center',
  },
  bottomTexts: {
    flexBasis: '100%',
  },
  bottomTextsWrapper: {
    flexWrap: 'wrap',
  },
  inputWrap,
});

interface ILoginResponse {
  error: string;
  token: string;
  totpActive: boolean;
  totpUrl: string;
}

interface ITotpVerificationResponse {
  error: string;
  token: string;
}

interface ILoginProps {
  onLogin: () => void;
  close: boolean;
}

// eslint-disable-next-line react/prop-types
const Login: FC<ILoginProps> = (props: ILoginProps) => {
  const { close, onLogin } = props;
  const classes = useStyles();
  const lang = useContext(languageContext);
  const { setAuthStatus } = useContext(authContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [totp, setTotp] = useState('');
  const [fetching, setFetching] = useState(false);
  const [loginResponse, setLoginResponse] = useState<ILoginResponse>();
  const [verificationResponse, setVerificationResponse] = useState<ITotpVerificationResponse>();
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  const submitBtnClasses = clsx({
    'a-button a-button--lemon has-loader': true,
    'is-loading': fetching,
  });

  useEffect(() => {
    if (username.trim() && password.trim() && !loginResponse?.token) {
      setSubmitBtnDisabled(false);
    } else if (!loginResponse?.error && loginResponse?.token && totp.trim()) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [username, password, totp, loginResponse]);

  const handleTotpVerification = (): void => {
    const reqData = {
      username: username.trim(),
      passcode: totp.trim(),
    };

    setFetching(true);

    const loginRequest = totpLogin(loginResponse?.token, reqData);
    const respData: ITotpVerificationResponse = {
      error: '',
      token: '',
    };
    loginRequest.then((res) => {
      res.match({
        Ok: (data) => {
          respData.token = data.value.token;
          setVerificationResponse(respData);
          onLogin();
        },
        Err: (loginErr) => {
          const errMsg = loginErr.err.errors.toString();
          respData.error = lang.getTranslatedText(errMsg);
          setVerificationResponse(respData);
        },
      })
    }).catch(() => {
      setVerificationResponse({
        error: 'An unexpected error occurred while verifying TOTP.',
        token: '',
      });
    }).finally(() => {
      if (!respData.error) {
        setAuthStatus({
          username: username.trim(),
          userDisplayName: '',
          registrarId: '',
          token: respData.token,
          deskproToken: '',
          role: '',
        });
      }
      setFetching(false);
    });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();


    if (loginResponse && loginResponse.token && totp) {
      handleTotpVerification();
      return;
    }

    setFetching(true);
    const reqData = { username: username.trim(), password: password.trim() };

    login(reqData).then((res) => {
      const respData: ILoginResponse = {
        error: '',
        token: '',
        totpActive: false,
        totpUrl: '',
      };

      res.match({
        Ok: (loginData) => {
          respData.token = loginData.value.token;
          respData.totpActive = loginData.value.totp_active;
        },
        Err: () => {
          respData.error = lang.getTranslatedText('Incorrect username or password.');
        }
      });

      return respData;
    }).then((respData: ILoginResponse) => {
      if (respData.token && !respData.totpActive) {
        return generateTotp(username, respData.token).then((totpRes) => {
          totpRes.match({
            Ok: (totpData) => {
              respData.totpUrl = totpData.value.url;
              setLoginResponse(respData);
            },
            Err: () => {
              setLoginResponse({
                error: 'An unexpected error occurred when generating TOTP.',
                token: '',
                totpActive: false,
                totpUrl: '',
              });
            }
          });
        });
      }

      setLoginResponse(respData);
    }).catch(() => {
      setLoginResponse({
        error: 'An unexpected error occurred.',
        token: '',
        totpActive: false,
        totpUrl: '',
      });
    }).finally(() => {
      setFetching(false);
    });
  };

  const handleClose = (): void => {
    setUsername("");
    setPassword("");
    setTotp("");
    setLoginResponse(undefined);
    onLogin();
  };

  /** Only necessary if haven't already performed first step in login process. */
  const footerElem: React.ReactNode = (
    <div className={classes.bottomTextsWrapper}>

      {!loginResponse?.token && (
        <p className={classes.bottomTexts}>
          <a href="/auth/request" className="u-link">
            <TranslatedText textKey="Forgot password?" />
          </a>
        </p>
      )}

      {!loginResponse?.token && (
        <p className={classes.bottomTexts}>
          <span>{lang.getTranslatedText("Want to apply for registrar")}</span>
          <br/>
          <a className="u-link" href="/apply">
            {lang.getTranslatedText("Apply for registrar account")}
          </a>
        </p>
      )}
    </div>
  );

  const formBtns: React.ReactNode = (
      <button type="submit"
              form="login-form"
              className={submitBtnClasses}
              aria-expanded={!close}
              aria-controls="modal-container"
              disabled={submitBtnDisabled || fetching}>
        <span className="a-button__text">{lang.getTranslatedText('Sign in')}</span>
        <LoadingIcon className="icon a-button__spinner" />
      </button>
  );

  const formNode: React.ReactNode = (
    <>
      <div>

        {/** Show secondary title for step two (TOTP). */}
        {loginResponse?.token && (
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <h1>{lang.getTranslatedText('One more step required')}</h1>
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right', color: green[500] }}>
              <CheckCircleIcon />
            </Grid>
          </Grid>
        )}

        <form
          className="m-modal__form"
          id="login-form"
          name="loginForm"
          noValidate
          autoComplete="off"
          onSubmit={(e): void => handleLogin(e)}
        >
          <IISInput
            className={classes.inputWrap}
            required
            id="username"
            label={lang.getTranslatedText('Username')}
            name="username"
            autoComplete="uname"
            autoFocus
            value={username}
            error={!fetching && loginResponse?.error ? true : false}
            disabled={loginResponse && loginResponse?.token ? true : false}
            onChange={(e): void => setUsername(e.target.value)}
          />
          <PasswordInput
            className={classes.inputWrap}
            required
            name="password"
            label={lang.getTranslatedText('Password')}
            type="password"
            id="password"
            error={!fetching && loginResponse?.error ? true : false}
            disabled={loginResponse && loginResponse?.token ? true : false}
            helperText={loginResponse?.error}
            value={password}
            onChange={(e): void => setPassword(e.target.value)}
            inputId="login-input-element"
          />

          {loginResponse?.token && !loginResponse?.totpActive && loginResponse?.totpUrl && (
            <Box>
              <Typography variant="body1">
                <TranslatedText textKey="Scan_1" /><em>Google Authenticator</em><TranslatedText textKey="Scan_2" />
              </Typography>
              <Box className={classes.qrCodeWrapper}>
                <QRCode value={loginResponse?.totpUrl} />
              </Box>
            </Box>
          )}

          {loginResponse?.token && !loginResponse?.error && (
            <Box>
              <Typography>
                <TranslatedText textKey="Please submit your TOTP" />
              </Typography>

              <IISInput
                className={classes.inputWrap}
                required
                name="totp"
                label={lang.getTranslatedText('Passcode')}
                id="totp"
                autoFocus
                value={totp}
                error={!fetching && verificationResponse?.error ? true : false}
                helperText={verificationResponse?.error}
                onChange={(e): void => setTotp(e.target.value)}
              />
            </Box>
          )}
        </form>
      </div>
    </>
  );

  return (
    <ModalWrapper modalInfo=''
                  hasForm={true}
                  modalTitle={loginResponse?.token ? '' : lang.getTranslatedText('Sign in')}
                  closed={close}
                  onClose={handleClose}
                  footerButtons={formBtns}
                  footerElem={footerElem}>
      {formNode}
    </ModalWrapper>
  );
};

export default Login;
