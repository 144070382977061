import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import CloseButton from '../Button/CloseButton';


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    color: "#000",
    [theme.breakpoints.up('sm')]: {
      minWidth: '80vw',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '80vw',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '40vw',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '40vw',
    }
  }
}));

export interface IModalWrapperProps {
  children: React.ReactNode;
  closed: boolean;
  hasForm: boolean;
  modalInfo?: string;
  modalTitle: string;
  onClose: () => void;
  footerButtons: React.ReactNode;
  footerElem?: React.ReactNode;
  modalId?: string;
}

const ModalWrapper: FC<IModalWrapperProps> = (props: IModalWrapperProps) => {
  const classes = useStyles();
  const { hasForm, footerButtons, children, modalTitle, modalInfo, closed, onClose, footerElem, modalId } = props;
  const containerClass= !hasForm ? 'm-modal__container' : 'm-modal__container m-modal__container--form';
  const id = modalId ? modalId : 'modal-container';

  return (
    <>
      <div id={id} className="m-modal m-modal--has-overlay" aria-hidden={closed}>
        <div className={`${containerClass} ${classes.modalContainer}`}>
          <CloseButton id={id} onClose={onClose} closed={closed} />

          <div className="m-modal__content">
            {hasForm && (
              <h1>{modalTitle}</h1>
            )}
            {!hasForm && (
              <h4>{modalTitle}</h4>
            )}
            {modalInfo &&
              (<p>{modalInfo}</p>)
            }

            {/** If children is a <form> it should have class "m-modal__form" */}
            {children}
          </div>

          <div className="m-modal__buttons u-m-t-2">
            {footerButtons}
          </div>

          {footerElem &&
            <div className="m-modal__content--form-text u-m-t-2">
              {footerElem}
            </div>
          }
        </div>
      </div>
    </>
  );
};


export default ModalWrapper;
