import React, {FC} from 'react';
import TranslatedText from '../../components/Language/TranslatedText';

export const NotFound: FC = () => {
    return (
        <div className="site__main">
            <div className="m-system-error">
                <div className="m-system-error__content">
                    <h1 className="m-system-error__heading">404</h1>
                    <p className="preamble">
                        <TranslatedText textKey="The page you where looking for could not be found." />
                    </p>
                    <p>
                        <TranslatedText textKey="Try" />
                        <a href="#" onClick={(event): void => {
                            event.preventDefault();
                            window.history.back();
                        }}>
                            <TranslatedText textKey="to go back in the web browser" />
                        </a>
                        <TranslatedText textKey="or go to the" />
                        <a href="/">
                            <TranslatedText textKey="start page" />
                        </a>.
                    </p>
                    <hr />
                    <em>404 error - <TranslatedText textKey="The page could not be found" /></em>
                </div>
            </div>
        </div>
    )
};

export default NotFound;
