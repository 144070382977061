import React, { FC, createContext } from 'react';
import PropTypes from 'prop-types';

/** Custom Hooks */
import useLanguageHandler from '../utils/custom-hooks/LanguageHandler';

/** Utils */
import { DEFAULT_LANGUAGE } from '../utils/Consts';
import { getStoredLanguage } from '../utils/Storage';
import { getTextForLanguage } from '../utils/Language';

/** Custom types */
import { LanguageTexts } from '../custom-types';
import { Alert } from '@mui/material';
import {Snackbar} from "@mui/material";

interface ILanguageContext {
  language: string;
  setLanguageStatus: (language: string) => void;
  languageTranslations: LanguageTexts;
  displayErrorMessage: (msg: string) => void;
  getTranslatedText: (msg: string) => string;
}

type LanguageProps = {
  children: React.ReactNode;
}

const languageContext = createContext<ILanguageContext>({
  language: DEFAULT_LANGUAGE,
  setLanguageStatus: () => { return; },
  languageTranslations: {},
  displayErrorMessage: () => { return; },
  getTranslatedText: (msg: string) => msg,
});

const { Provider } = languageContext;

const LanguageProvider: FC<LanguageProps> = ({
  children,
}) => {
  const { language, setLanguageStatus, languageTranslations } = useLanguageHandler(
    getStoredLanguage(),
  );

  const getTranslatedText = (textKey: string): string => {
    return getTextForLanguage(language, textKey, languageTranslations);
  };

  const [errorMsg, setErrorMsg] = React.useState('');
  const displayErrorMessage = (msg: string): void => {
    getTextForLanguage(language, msg, languageTranslations);
  };
  const resetErrorMsg = (): void => {
    setErrorMsg('');
  };

  return (
    <Provider value={{ language, setLanguageStatus, languageTranslations, displayErrorMessage, getTranslatedText }}>
      {children}
      {/** For showing error messages */}
      <Snackbar open={errorMsg !== ''} autoHideDuration={6000} onClose={resetErrorMsg}>
        <Alert onClose={resetErrorMsg} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    </Provider>
  );
};

LanguageProvider.propTypes = {
   children: PropTypes.node
};

export { LanguageProvider as default, languageContext };
