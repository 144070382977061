import React, { FC, useContext, useEffect, lazy, Suspense } from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

/** Context */
import { authContext } from '../../contexts/AuthContext';
import PrivateRoute from '../../components/PrivateRoute';

/** Presentation */
import Landing from './Landing';
import TopNav from "../../components/Navigation/TopNav";
import ErrorBoundary from "../../components/ErrorBoundary";
import Footer from "../../components/Navigation/Footer"
import NotFound from './NotFound';
import CookieDisclaimer from '../../components/alert/CookieDisclaimer';
import Spinner from "../../components/Spinner";
import SkipNav from '../../components/Navigation/SkipNav';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
  },
  onFocus: {
    '& *:focus': {
      boxShadow: '0 0 0 2px inset #ff4069 !important',
      outline: 0,
    }
  },
  spinner: {
    width: 200,
    height: 200,
    margin: "0 auto",
  },
}));


const Dashboard = lazy(() => import('./Dashboard'));
const About = lazy(() => import('./About'));
const Apply = lazy(() => import('../apply/Apply'));
const ForgotPassword = lazy(() => import('../auth/ForgotPassword'));
const WhoisTerms = lazy(() => import('../whois/WhoisTerms'));
const Invoices = lazy(() => import('../invoices/Invoices'));
const Domains = lazy(() => import('../domains/Domains'));
const Stats = lazy(() => import('../stats/Stats'));
const AccountPage = lazy(() => import('../account/AccountPage'));
const EPPUserPage = lazy(() => import('../account/users/EPPUserPage'));

const RootContainer: FC = () => {
  const { auth } = useContext(authContext);
  const userLoggedIn = auth.username && auth.token;
  const classes = useStyles();
  const isAuthenticated = !!(auth.username && auth.token)
  const navigationID = "siteNav"
  const mainContentID = "siteMain"

  useEffect(() => {
    document.body.addEventListener('keydown', (event) => {
      const keyCode = event.key;
      if (keyCode === 'Tab' ) {
        const element = event.target as HTMLElement;
        element.classList.add(classes.onFocus);
      }
    });

    document.body.addEventListener('mousedown', (event) => {
      const element = event.target as HTMLElement;
      element.classList.remove(classes.onFocus);
    });
  }, []);

  return (
    <div className="site">
      <SkipNav mainContentID={mainContentID} navigationID={navigationID}/>

      <TopNav navigationID={navigationID}/>

      <Box id={mainContentID} className={`${classes.root}`}>
        <Router>
        <ErrorBoundary>
        <Suspense fallback={<Spinner className={classes.spinner}/>}>
          <Switch>
            <Route exact path="/">
              {userLoggedIn ? <Dashboard /> : <Landing />}
            </Route>
            <Route exact path="/auth/request">
              <ForgotPassword />
            </Route>
            <Route exact path="/apply">
              <Apply />
            </Route>
            <Route exact path="/om-webbplatsen" component={About}/>
            <Route exact path="/whois-terms">
              <WhoisTerms />
            </Route>
            <PrivateRoute path="/account/users/epp/:username" redirectPath="/"
                          isAuthenticated={isAuthenticated} component={EPPUserPage} />
            <PrivateRoute path="/account" redirectPath="/" isAuthenticated={isAuthenticated} component={AccountPage} />
            <PrivateRoute path="/domains" redirectPath="/" isAuthenticated={isAuthenticated} component={Domains} />
            <PrivateRoute path="/invoices" redirectPath="/" isAuthenticated={isAuthenticated} component={Invoices} />
            <PrivateRoute path="/stats" redirectPath="/" isAuthenticated={isAuthenticated} component={Stats} />

    -       { /* 404 Handling, make sure this are the last two routes */ }
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
          </Suspense>
          </ErrorBoundary>
        </Router>
      </Box>
      <CookieDisclaimer />
      <Footer />
    </div>
  );
};

export default RootContainer;
