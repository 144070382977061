import React, {FC} from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  routeTitle: {
    marginTop: '1rem',
    padding: '0 0.5rem',
    [theme.breakpoints.up('lg')]: {
      marginTop: '2rem',
    },
  },
  maxWidthNormal: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
    },
  },
  maxWidthExtended: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '70%',
    },
  },
  description: {
    marginBottom: '1.75rem',
  },
}));

interface IRouteTitleProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  extendMaxWidth?: boolean;
}

const RouteTitle: FC<IRouteTitleProps> = (props: IRouteTitleProps) => {
  const { title, description, children, extendMaxWidth } = props;
  const classes = useStyles();
  const maxWidthClass = extendMaxWidth ? classes.maxWidthExtended : classes.maxWidthNormal;

  return (
    <div className={`${classes.routeTitle} ${maxWidthClass}`}>
      <h1 className="supersize">
        {title}
      </h1>
      <div className={classes.description}>
        {description && (
          <p>
            {description}
          </p>
        )}

        {children}
      </div>
    </div>
  );
};

export default RouteTitle;
