import React, { FC, useContext } from 'react';
import { languageContext } from '../../contexts/LanguageContext';

export interface ISkipNav {
    navigationID: string;
    mainContentID: string;
}

export const SkipNav: FC<ISkipNav> = (props: ISkipNav) => {
    const {navigationID, mainContentID} = props;
    const lang = useContext(languageContext);

    return (
        <ul className="skip" role="navigation">
            <li><a href={`#${navigationID}`}>{lang.getTranslatedText('To navigation')}</a></li>
            <li><a accessKey="s" href={`#${mainContentID}`}>{lang.getTranslatedText('To content')}</a></li>
        </ul>
    )
}

export default SkipNav;