import React, { FC } from 'react';

import { ReactComponent as CloseIcon } from '../../images/icon-close.svg';
import TranslatedText from "../Language/TranslatedText";

interface ICloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    id: string;
    closed: boolean;
}

export const CloseButton: FC<ICloseButtonProps> = (props: ICloseButtonProps) => {
    const {onClose, closed, id, ...rest} = props;
    return (
        <button {...rest}
                type="button"
                className="a-button a-button--standalone-icon a-button--transparent"
                aria-expanded={!closed}
                aria-controls={id}
                onClick={onClose}>
            <TranslatedText className="a-button__text u-visuallyhidden" textKey='close'/>
            <CloseIcon className="icon a-button__icon"></CloseIcon>
        </button>
    )
}

export default CloseButton;
