/** Custom types */
import {
  WhoisDomainResponse,
  WhoisContactResponse,
  WhoisResponse, ErrorResponse, StatsGeneralResponse, StatsRegistrarResponse, StatsRankResponse
} from '../custom-types/Request';

import { Result, Err, Ok } from './Result';
import { User } from '../custom-types/User';
import {RegistrarContact, RegistrarListingDetails} from "../custom-types/Registrar";
import {IStatsGeneralTypes, IStatsRankTypes, IStatsRegistrarTypes, StatsDateEntries} from "../custom-types/Stats";
import { format, subDays} from 'date-fns'
import { InvoiceState } from '../custom-types/Invoice';

export const downloadBase64AsFile = (bytesBase64: string, mimeType: string, fileName: string) => {
  const byteCharacters = atob(bytesBase64);
  const byteNumbers = new Array(byteCharacters.length);
 
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  const a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 Get a common grouped state "name" for InvoiceStates.
 * */
export const invoiceStateGroupName = (state: InvoiceState): string => {
  switch (state) {
    case InvoiceState.Draft:
    case InvoiceState.Sending: {
      return 'Open';
    }
    case InvoiceState.Sent: {
      return 'Unpaid';
    }
    case InvoiceState.Paid:
    case InvoiceState.CCPaid: {
      return 'Paid';
    }
    default: {
      return 'N/A';
    }
  }
};

export const handleNotOkResponse = (res: Response): Promise<ErrorResponse> => {
  const errObj: ErrorResponse = {errors: [res.statusText]};

  return Promise.reject(res.json().then((error) => {
      if (error && {}.propertyIsEnumerable.call(error, 'errors')) {
        errObj.errors = error.errors;
      }

      if (error && {}.propertyIsEnumerable.call(error, 'fields')) {
        errObj.fields = error.fields;
      }

      return errObj;
    }).catch(() => {
      // Catch empty body / cannot be json parsed
      return errObj;
    })
  )
};

  export const toSnakeCase = (str: string): string => {
  // TODO use lib instead
  const snakeRe = new RegExp(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
  const matches = str.match(snakeRe);
  if (matches !== null) {
    return matches.map(x => x.toLowerCase()).join('_');
  }
  return '';
};

// isDataURI will check that the provided uri matches the expected
// format used when downloading PDFs.
export const isDataURI = (uri: string) => {
  return /^data:([\w+/-]+);charset=utf-8;base64,/.test(uri);
}

export const isStatsGeneralV2Response = (respData: StatsGeneralResponse): respData is IStatsGeneralTypes => {
  return !Array.isArray(respData);
}

export const isStatsRegistrarV2Response = (respData: StatsRegistrarResponse): respData is IStatsRegistrarTypes => {
  return !Array.isArray(respData);
}

export const isStatsRankV2Response = (respData: StatsRankResponse): respData is IStatsRankTypes => {
  return !Array.isArray(respData);
}

export const isWhoisDomainResponse = (whoisData?: WhoisResponse): whoisData is WhoisDomainResponse => {
  if (whoisData && 'domain' in whoisData) {
    return true;
  }

  return false
}

export const isWhoisContactResponse = (whoisData?: WhoisResponse): whoisData is WhoisContactResponse => {
  if (whoisData && 'identifier' in whoisData) {
    return true;
  }

  return false
}

export const getWhoisResponseType = (whoisData: WhoisResponse): string => {
  if (isWhoisDomainResponse(whoisData)) return 'domain';

  return 'contact';
}

export const getWhoisName = (whoisData: WhoisResponse): string => {
  if (isWhoisDomainResponse(whoisData)) {
    return whoisData.domain;
  } else if (isWhoisContactResponse(whoisData)) {
    return whoisData.identifier;
  }

  return '';
}

export const matchesCurrentPath = (pathname: string): boolean => {
  return window.location.pathname.match(`${pathname}/?.*`) !== null;
}

export const tldFromTldLid = (tldLid: string): Result<string, string> => {
  const parts = tldLid.split('-');
  if (parts.length === 0) return new Err("Invalid TLD-Lid")

  return new Ok(parts[0]);
}

export const setMissingTLDs = (user: User): User => {
    if (user.tlds && user.tlds.length > 0) return user;

    if (user.tld_lid) {
      // if we have [se-test, se-prod] we will take the se part of the first
      const tldFromLid = user.tld_lid[0].split('-')[0]
      user.tlds = [tldFromLid];
      return user;
    }

    // Neither tlds or tld_lid is set just set tld to an empty array
    user.tlds = [];
    return user;
}

/**
 * Will return the object with empty strings replaced
 * with null.
 * Necessary because API returns some registrars having empty
 * strings as values but will only accept null instead when
 * saving.
 * @param registrarObj either a registrar's contact or details
 */
export const nullCheckRegistrarVals = (
  registrarObj: RegistrarContact | RegistrarListingDetails
): RegistrarContact | RegistrarListingDetails => {
  const newObj: {[key: string]: string | null} = {};
  Object.entries(registrarObj).forEach(([key, val]) => {
    if (typeof val !== 'string') return;

    if (val) {
      val = val.trim()
    }
    newObj[key] = !val ? null : val;
  });

  return Object.assign(registrarObj, newObj);
};

// If no entry found for current day.
// Try to get data for dates by stepping "dateLeeway" days back.
export const getStatsDateData = (dateEntries: StatsDateEntries | undefined): {date: string; value: number} | undefined => {
  if (dateEntries === undefined) return;
  const dateLeeway = 3;

  for (let i = 0; i <= dateLeeway; i++) {
    const dateWithLeeway = format(subDays(new Date(), i ), 'yyyy-MM-dd');
    if (dateWithLeeway in dateEntries) {
      return {date: dateWithLeeway, value: dateEntries[dateWithLeeway]};
    }
  }

  return;
};

// Will extract sum of the values for the latest 30 days from
// StatsDateEntries object.
export const getAggregatedStatsDateData = (dateEntries: StatsDateEntries): number => {
  const dateLeeway = 30;
  let aggregatedValue = 0;

  for (let i = 0; i <= dateLeeway; i++) {
    const dateWithLeeway = format(subDays(new Date(), i), 'yyyy-MM-dd');
    if (dateWithLeeway in dateEntries) {
      aggregatedValue += Number(dateEntries[dateWithLeeway]);
    }
  }

  return aggregatedValue;
};

export const parseErrorReason = (reason: ErrorResponse, defaultError?: string): string => {
  if (reason.errors && reason.fields) {
    return 'Errors for fields:' + Object.keys(reason.fields).toString();
  }

  if (reason.errors && reason.errors.length) {
    return reason.errors.toString();
  }

  if (defaultError) {
    return defaultError;
  }

  return 'An error occurred';
};
