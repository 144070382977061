import React, {FC, useContext, useState} from 'react';
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import {languageContext} from "../../contexts/LanguageContext";

interface IStiftelsenInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  label: string;
  endIcon?: JSX.Element;
  allowShadyCharacters?: boolean;
}

const IISInput: FC<IStiftelsenInputProps> = (props: IStiftelsenInputProps) => {
  const lang = useContext(languageContext);
  const inputClass = props.rows ? 'a-textarea' : 'a-input';
  const forbiddenChars = ['<', '>', '\\'];
  const forbiddenHelpText = `${lang.getTranslatedText('Forbidden characters include:')} ${forbiddenChars.toString()}`;
  const [preventedForbiddenChar, setPreventedForbiddenChar] = useState(false);

  return (
    <div className={props.className}>
      <label htmlFor={props.id}>{props.label} {props.required ? '*' : ''}</label>
      <TextField
        className={inputClass}
        variant="outlined"
        margin="none"
        fullWidth
        id={props.id}
        name={props.name}
        error={props.error || preventedForbiddenChar}
        autoFocus={props.autoFocus}
        required={props.required}
        defaultValue={props.defaultValue}
        value={props.value}
        helperText={preventedForbiddenChar ? forbiddenHelpText : props.helperText}
        placeholder={props.placeholder}
        rows={props.rows}
        multiline={props.multiline}
        type={props.type}
        disabled={props.disabled}
        InputProps={{
          endAdornment: props.endIcon,
        }}
        onChange={(e): void => {
          // Prevent any forbidden characters from being inputted.
          // This will also show an error text and stop passed onChange
          // from being called.
          const val = e.currentTarget.value;
          const containsForbidden = !props.allowShadyCharacters && forbiddenChars.some((c) => val.includes(c))
          if (containsForbidden) {
            setPreventedForbiddenChar(true);
            const forbiddenCharsRegexp = new RegExp(/[\\<>]/g);
            const cleanVal = val.replace(forbiddenCharsRegexp, '');
            e.currentTarget.value = cleanVal;
            return;
          }

          if (preventedForbiddenChar) {
            setPreventedForbiddenChar(false);
          }

          if (props.onChange) {
            props.onChange(e)
          }
        }}
      />
    </div>
  );
};

export default IISInput;
