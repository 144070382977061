import React, { FC } from 'react';

import ModalWrapper from '../Modal/ModalWrapper';
import TranslatedText from '../Language/TranslatedText';
import LoadingButton from '../Button/LoadingButton';

interface IConfirmProps {
    onConfirm?: () => void;
    onCancel: () => void;
    confirmButtonDisabled: boolean;
    extraConfirmButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
    modalTitle: string;
    closed: boolean;
    modalInfo?: string;
    hasForm: boolean;
    withLoader: boolean;
    isLoading?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    footerElem?: React.ReactNode;
    children?: React.ReactNode;
    modalId?: string;
}

export const ConfirmModal: FC<IConfirmProps> = (props: IConfirmProps) => {

    const { onConfirm,
            onCancel,
            children,
            confirmButtonDisabled,
            confirmButtonText,
            extraConfirmButtonProps,
            cancelButtonText,
            withLoader,
            isLoading,
            modalId,
            ...rest } = props;
    const id = modalId ? modalId : 'confirm-modal';

    const ConfirmCancelButtons = (
        <div>
            <button id={`${id}-cancel-btn`}
                    onClick={onCancel}
                    className="a-button a-button--ruby-light"
                    style={{ marginRight: 7 }}>
                <TranslatedText textKey={cancelButtonText ? cancelButtonText : 'Cancel'} className="a-button__text" />
            </button>
            {
                !withLoader ?
                    <button {...extraConfirmButtonProps}
                            onClick={onConfirm}
                            className="a-button a-button--lemon"
                            disabled={confirmButtonDisabled}
                            id={`${id}-confirm-btn`}>
                        <TranslatedText
                            textKey={confirmButtonText ? confirmButtonText : 'Confirm'}
                            className="a-button__text" />
                    </button> :
                    <LoadingButton {...extraConfirmButtonProps}
                                   onClick={onConfirm}
                                   isLoading={!!isLoading}
                                   id={`${id}-confirm-btn`}
                                   text={confirmButtonText ? confirmButtonText : 'Confirm'}
                                   disabled={confirmButtonDisabled} />
            }
        </div>
    )

    return (
        <ModalWrapper footerButtons={ConfirmCancelButtons}
                      onClose={onCancel}
                      modalId={id}
                      {...rest}>
            {children}
        </ModalWrapper>
    )
}

export default ConfirmModal;
