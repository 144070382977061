import React, {FC, useContext} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ListItem, ListItemIcon } from "@mui/material";

import englishFlag from '../../images/flag_usa.png';
import swedishFlag from '../../images/flag_sweden.png';

/** Context */
import { languageContext } from '../../contexts/LanguageContext';

/** Utils */
import { DEFAULT_LANGUAGE } from '../../utils/Consts';
import ArrowMenuList from "../Navigation/ArrowMenuList";

const useStyles = makeStyles({
  nested: {
    paddingLeft: 32,
  },
});

const ToggleLanguageDrawer: FC = () => {
  const classes = useStyles();
  const lang = useContext(languageContext);

  const onToggleLanguage = (): void => {
    if (lang.language !== DEFAULT_LANGUAGE) {
      lang.setLanguageStatus(DEFAULT_LANGUAGE);
    } else {
      lang.setLanguageStatus('sv');
    }
  };

  const headerIcon = (<img src={lang.language !== DEFAULT_LANGUAGE ? swedishFlag : englishFlag} alt="flag icon"/>);
  const headerText: string = (lang.language !== DEFAULT_LANGUAGE ? 'Svenska' : 'English');

  return (
    <ArrowMenuList headerText={headerText} headerIcon={headerIcon}>
      <ListItem button className={classes.nested} onClick={onToggleLanguage}>
        <ListItemIcon>
          <img src={lang.language === DEFAULT_LANGUAGE ? swedishFlag : englishFlag} alt="flag icon"/>
        </ListItemIcon>
        {lang.language === DEFAULT_LANGUAGE ? 'Svenska' : 'English'}
      </ListItem>
    </ArrowMenuList>
  );
};

export default ToggleLanguageDrawer;
