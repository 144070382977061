import React, { FC, createContext } from 'react';
import PropTypes from 'prop-types';

/** Custom types */
import { UserAuth } from '../custom-types';

/** Custom Hooks */
import useAuthHandler from '../utils/custom-hooks/AuthHandler';

/** Utils */
import { DEFAULT_USER_AUTH } from '../utils/Consts';
import { getStoredUserAuth } from '../utils/Storage';

interface IAuthContextInterface {
  auth: UserAuth;
  setAuthStatus: (userAuth: UserAuth) => void;
  setUnauthStatus: () => void;
}

const authContext = createContext<IAuthContextInterface>({
  auth: DEFAULT_USER_AUTH,
  setAuthStatus: (): void => { return },
  setUnauthStatus: (): void => { return },
});

const { Provider } = authContext;
const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { auth, setAuthStatus, setUnauthStatus } = useAuthHandler(
    getStoredUserAuth(),
  );

  return (
    <Provider value={{ auth, setAuthStatus, setUnauthStatus }}>
      {children}
    </Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
}

export { AuthProvider as default, authContext };
