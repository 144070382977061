import { LanguageTexts } from '../custom-types';

/** Get the text representation for a key in selected language */
export const getTextForLanguage = (language: string, textKey: string, translations: LanguageTexts): string => {
  if (typeof translations[textKey] === 'undefined') {
    return textKey;
  }

  const langObj = translations[textKey];

  if (typeof langObj[language] === 'undefined') {
    return textKey;
  }

  return langObj[language];
};
