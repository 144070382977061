import React, { FC, useContext } from 'react';

import {ReactComponent as LoadingIcon} from "../../images/icon-spinner.svg";
import {languageContext} from '../../contexts/LanguageContext';

interface ILoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    isLoading: boolean;
    disabled?: boolean;
}

export const LoadingButton: FC<ILoadingButtonProps> = (props: ILoadingButtonProps) => {
    const { text, isLoading, disabled, ...rest} = props;
    const lang = useContext(languageContext);
    return (
        <button {...rest}
            className={`a-button a-button--lemon has-loader ${isLoading ? 'is-loading' : ''}`}
            disabled={isLoading || disabled}>
        <span className="a-button__text">
          {lang.getTranslatedText(text)}
        </span>
      <LoadingIcon className="icon a-button__spinner" />
    </button>
    )
}

export default LoadingButton;
