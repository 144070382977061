import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { List, ListItem } from '@mui/material';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ViewListIcon from '@mui/icons-material/ViewList';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { Colors } from '../../utils/Consts';
import { heading } from '../../utils/Classes';
import TranslatedText from '../Language/TranslatedText';
import WhoisSearchBar from '../../routes/whois/WhoisSearchBar';

const useStyles = makeStyles((theme) => createStyles({
    secondaryNav: {
        background: Colors.cyberspace,
        color: Colors.ash,
        '& .wrapper': {
            [theme.breakpoints.down('lg')]: {
                padding: 0,
            }
        },
    },
    horizontalMenu: {
        height: "100%",
        gridColumnStart: 2,
        display: "flex",
        flexDirection: "row",
        padding: 0,
        alignItems: "center",
    },
    menuItem: {
        flexDirection: "column",
        justifyContent: "center",
        width: '5.3rem',
        padding: '.75rem 0',
        margin: '0 10px',
        '&:hover': {
            backgroundColor: Colors.ruby,
        },
        '&:first-of-type': {
          marginLeft: 0,
        },
        '&:last-of-type': {
            marginRight: 0,
        },
        '& > h4': {
          padding: 0,
        }
    },
    itemsHolder: {
        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
    },
    isCurrent: {
        backgroundColor: Colors.ruby,
    },
    heading
}));

const secondaryNavItems = [
    {
        href: "/",
        text: <TranslatedText textKey="Start" />,
        icon: <DashboardIcon />
    },
    {
        href: "/account",
        text: <TranslatedText textKey="Account" />,
        icon: <AccountBoxIcon />
    },
    {
        href: "/domains",
        text: <TranslatedText textKey="Domains" />,
        icon: <ViewListIcon />
    },
    {
        href: "/invoices",
        text: <TranslatedText textKey="Invoices"/>,
        icon: <ReceiptIcon />
    },
    {
        href: "/stats",
        text: <TranslatedText textKey="Stats"/>,
        icon: <AssessmentIcon />
    }
]

const matchPathname = (pathname: string, href: string): boolean => {
    if (href === '/') return pathname === '/';

    return pathname.match(`${href}/?.*`) !== null;
}

export const SecondaryNav: FC = () => {
    const classes = useStyles();
    return (
        <Box className={classes.secondaryNav} id="secondaryNav">
            <div className="wrapper">
                <div className={classes.itemsHolder}>
                    <List className={classes.horizontalMenu}>
                        {
                            secondaryNavItems.map((value, index) => {
                                return (
                                  <ListItem key={index}
                                            button
                                            component="a" href={value.href}
                                            className={`${classes.menuItem} ${clsx(
                                              matchPathname(window.location.pathname, value.href) && classes.isCurrent)}`}>
                                      {value.icon}
                                      <Typography className={classes.heading} variant="h4">{value.text}</Typography>
                                  </ListItem>
                                )
                            })
                        }
                    </List>
                    <WhoisSearchBar />
                </div>
            </div>
        </Box>
    )
}

export default SecondaryNav;
