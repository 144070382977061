import React, {FC, useContext, useState} from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import { ReactComponent as ArrowDown } from '@internetstiftelsen/styleguide/src/atoms/icon/arrow-down.svg';
import englishFlag from '../../images/flag_usa.png';
import swedishFlag from '../../images/flag_sweden.png';

/** Context */
import { languageContext } from '../../contexts/LanguageContext';

/** Utils */
import { DEFAULT_LANGUAGE } from '../../utils/Consts';
import { menuLink, isCurrent } from '../../utils/Classes';

const useStyles = makeStyles((_: Theme) => createStyles({
  flagIcon: {
    width: '0.9rem',
    height: '0.6rem',
    marginRight: '0.6rem',
    marginTop: '.25rem',
  },
  downArrow: {
    width: '1.1rem',
    height: '1.1rem',
    marginLeft: '0.5rem',
    marginTop: '.4rem',
  },
  dropDownBtn: {
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  menuLink,
  isCurrent
}));

const ToggleLanguageBtn: FC = () => {
  const classes = useStyles();
  const lang = useContext(languageContext);

  const menuBtnClass = clsx({
    [classes.menuLink]: true,
    [classes.dropDownBtn]: true,
    'a-main-menu__list__link': true,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onToggleLanguage = (): void => {
    if (lang.language !== DEFAULT_LANGUAGE) {
      lang.setLanguageStatus(DEFAULT_LANGUAGE);
    } else {
      lang.setLanguageStatus('sv');
    }

    setAnchorEl(null);
  };

  return (
    <>
      <button
        className={menuBtnClass}
        color="inherit"
        aria-controls={anchorEl ? 'language-menu' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-label="select a language"
        aria-haspopup="true"
        onClick={(event): void => setAnchorEl(event.currentTarget)}
      >
        <span>
          <img className={classes.flagIcon}
               src={lang.language !== DEFAULT_LANGUAGE ? swedishFlag : englishFlag}
               alt="flag icon"/>
        </span>
        {lang.language !== DEFAULT_LANGUAGE ? 'Svenska' : 'English'}
        <ArrowDown className={classes.downArrow} />
      </button>
      <Menu id="language-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={(): void => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
      >
        <MenuItem className={classes.menuLink} onClick={onToggleLanguage}>
          <span>
            <img className={classes.flagIcon}
                 src={lang.language === DEFAULT_LANGUAGE ? swedishFlag : englishFlag}
                 alt="flag icon"/>
          </span>
            {lang.language === DEFAULT_LANGUAGE ? 'Svenska' : 'English'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default ToggleLanguageBtn;
