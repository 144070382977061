import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { WhoisDomainResponse } from '../../custom-types/Request';
import { Colors } from '../../utils/Consts';
import TranslatedText from '../../components/Language/TranslatedText';

interface IWhoisDomainDataProps {
    data: WhoisDomainResponse;
    tld: string;
    updateFunction: (searchText: string) => void;
}

interface IWhoisRowProps {
    rowTitle: string;
    rowData: string;
    isLinkable?: boolean;
    updateFunction?: (searchText: string) => void;
}

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto",
        borderBottom: "1px solid black",
        '&:nth-child(even)': {
            backgroundColor: Colors.ash,
        },
    },
    whoisItem: {
        padding: 4,
    },
    descriptor: {
        gridColumnStart: 1,
        fontWeight: "bold",
    },
    value: {
        gridColumnStart: 2,
        textAlign: "right",
    },
    sublistItem: {
        padding: 0,
        magin: 0,
        fontSize: "0.7rem",
    }
}))

const WhoisRow: FC<IWhoisRowProps> = (props: IWhoisRowProps) => {
    const classes = useStyles();
    const { rowTitle, rowData, isLinkable, updateFunction } = props;

    return (
        <Box className={classes.root}>
            <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey={rowTitle} />
            </span>
            <span className={`${classes.value} ${classes.whoisItem}`}>
                {
                    (isLinkable && updateFunction && rowData !== '(not shown)') ?
                    <a href="#"
                           onClick={(): void => {updateFunction(rowData)}}
                           className="u-link">
                        {rowData}
                    </a> :
                    rowData
                }
            </span>
        </Box>
    )
}

export const WhoisDomainDataList: FC<IWhoisDomainDataProps> = (props: IWhoisDomainDataProps) => {
    const classes = useStyles();
    const { data, tld, updateFunction } = props;

    return (
        <>
            <Box className={classes.root}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}></span>
                <span className={`${classes.value} ${classes.whoisItem}`}>
                    <Typography variant="h4">{tld}</Typography>
                </span>
            </Box>

            <WhoisRow rowTitle="Domain name" rowData={data.domain} />

            <WhoisRow rowTitle="Domain status" rowData={data.state} />

            <WhoisRow rowTitle="Registrant" rowData={data.holder}
                      isLinkable updateFunction={updateFunction} />


            <WhoisRow rowTitle="Created" rowData={data.created_date} />

            {data.modified_date && <WhoisRow rowTitle="Changed" rowData={data.modified_date} />}

            <WhoisRow rowTitle="Expires" rowData={data.expires} />

            {data.transfer_date && <WhoisRow rowTitle="Transfered" rowData={data.transfer_date} />}

            {data.deact_date && <WhoisRow rowTitle="Deactivation date" rowData={data.deact_date} />}

            {data.delete_date && <WhoisRow rowTitle="Deletion date" rowData={data.delete_date} />}

            {data.release_date && <WhoisRow rowTitle="Release date" rowData={data.release_date} />}

            <Box className={classes.root}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Nameserver" />
                </span>
                <Box className={`${classes.value} ${classes.whoisItem}`}>
                    {
                        data.name_servers.map((server, index) => {

                            const subordinateHost = server.name.endsWith(data.domain);
                            return (
                                <Typography className={classes.sublistItem} key={index}>
                                  {server.name}
                                  {subordinateHost && (
                                    server.ips.map((ip) => (
                                      <>
                                        <br />
                                        {ip}
                                      </>
                                    ))
                                  )}
                                </Typography>
                            )
                        })
                    }
                </Box>
            </Box>

            <WhoisRow rowTitle="DNSSEC" rowData={data.dnssec} />

            <WhoisRow rowTitle="Registry-lock" rowData={data.registry_lock} />

            <Box className={classes.root}>
               <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                   <TranslatedText textKey="Status" />
                </span>
                <Box className={`${classes.value} ${classes.whoisItem}`}>
                    {
                        data.statuses.map((status, index) => {
                            return (
                                <Typography className={classes.sublistItem} key={index}>{status.status}</Typography>
                            )
                        })
                    }
                </Box>
            </Box>

            <WhoisRow rowTitle="Registrar" rowData={data.registrar_name} />
        </>
    )
}

export default WhoisDomainDataList
