import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { WhoisContactResponse } from '../../custom-types/Request';
import { Colors } from '../../utils/Consts';
import TranslatedText from '../../components/Language/TranslatedText';

interface IWhoisContactDataProps {
    seData?: WhoisContactResponse;
    nuData?: WhoisContactResponse;
}

const useStyles = makeStyles(() => createStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto",
        borderBottom: "1px solid black",
        '&:nth-child(even)': {
            backgroundColor: Colors.ash,
        },
    },
    threeColumn: {
        gridTemplateColumns: "1fr 1fr 1fr"
    },
    whoisItem: {
        padding: 4,
    },
    descriptor: {
        gridColumnStart: 1,
        fontWeight: "bold",
    },
    value: {
        textAlign: "right",
    },
    sublistItem: {
        padding: 0,
        magin: 0,
        fontSize: "0.7rem",
    }
}))

// TODO: Refactor out the Box for each row to its own component
export const WhoisContactDataList: FC<IWhoisContactDataProps> = (props: IWhoisContactDataProps) => {
    const classes = useStyles();
    const { seData, nuData } = props;

    return (
        <>
            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}></span>
                {seData && <span className={`${classes.value} ${classes.whoisItem}`}>
                           <Typography variant="h4">se</Typography></span>}
                {nuData && <span className={`${classes.value} ${classes.whoisItem}`}>
                           <Typography variant="h4">nu</Typography></span>}
            </Box>

            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Contact ID" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.identifier}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.identifier}</span> }
            </Box>

            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Email" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.email}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.email}</span> }
            </Box>

            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Contact person" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.name}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.name}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Organization" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.org}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.org}</span> }
            </Box>

            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Organization number" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.orgno}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.orgno}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Address 1" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.street1}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.street1}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Address 2" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.street2}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.street2}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Address 3" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.street3}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.street3}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Postal code" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.postalcode}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.postalcode}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="State/province" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.state_province}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.state_province}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Postal area" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.city}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.city}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Country code" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.country}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.country}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Telephone" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.phone}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.phone}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Fax" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.fax}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.fax}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Created" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.created}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.created}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Modified" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.modified}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.modified}</span> }
            </Box>


            <Box className={`${classes.root} ${seData && nuData && classes.threeColumn}`}>
                <span className={`${classes.descriptor} ${classes.whoisItem}`}>
                    <TranslatedText textKey="Registrar" />
                </span>
                { seData && <span className={`${classes.value} ${classes.whoisItem}`}>{seData.registrar_name}</span> }
                { nuData && <span className={`${classes.value} ${classes.whoisItem}`}>{nuData.registrar_name}</span> }
            </Box>

        </>
    )
}

export default WhoisContactDataList;
