import React from 'react';
import './App.scss';
import RootContainer from './routes/root/RootContainer';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

/** Context API */
import AuthContextProvider from './contexts/AuthContext';
import LanguageContextProvider from './contexts/LanguageContext';
import ThemeContext from './contexts/ThemeContext';
import CookieProvider from "./contexts/CookieContext";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/naming-convention
  interface DefaultTheme extends Theme {}
}

function App(): JSX.Element {
  return (
    <LanguageContextProvider>
      <AuthContextProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={ThemeContext}>
            <CookieProvider>
              <RootContainer />
            </CookieProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthContextProvider>
    </LanguageContextProvider>
  );
}

export default App;
