import React, { FC } from 'react';

import { WhoisResponse, WhoisContactResponse, WhoisDomainResponse } from '../../custom-types/Request';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { isWhoisDomainResponse } from '../../utils/Helpers';
import WhoisDomainDataList from './WhoisDomainDataList';
import WhoisContactDataList from './WhoisContactDataList';
import TranslatedText from '../../components/Language/TranslatedText';

interface IDataList {
    tld: string;
    data?: WhoisResponse;
    additionalData?: WhoisContactResponse;
    resultFound: boolean;
    updateFunction: (searchText: string) => void;
}


const useStyles = makeStyles(() => createStyles({
    root: {
        color: "#000",
        width: "100%",
        fontSize: "0.7rem",
    },
}))

export const WhoisDataList: FC<IDataList> = (props: IDataList) => {
    const classes = useStyles();
    const { tld, data, additionalData, resultFound, updateFunction } = props;

    return (
        <Box className={classes.root}>
            {resultFound && (data || additionalData) && (isWhoisDomainResponse(data) ?
                            <WhoisDomainDataList tld={tld}
                                                 data={data as WhoisDomainResponse}
                                                 updateFunction={updateFunction} /> :
                            <WhoisContactDataList seData={data as WhoisContactResponse} nuData={additionalData} />)}
            {!resultFound && <Typography variant="h4">
                               <TranslatedText textKey="No result found" />
                             </Typography>}
        </Box>
    )
}

export default WhoisDataList;
