// import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { Colors } from '../utils/Consts';

export const fontFamilyBase = 'HK Grotesk, sans-serif';
export const fontFamilyHeading = 'HK Grotesk Semibold, sans-serif';
export const fontFamilyMedium = 'HK Grotesk Medium, sans-serif';
export const fontFamilyBold = 'HK Grotesk Bold, sans-serif';
export const fontFamilyMono = 'Roboto Mono Regular, monospace';

export const sizeBase = 18;
export const baseUnit = sizeBase / 2;

export const calculateSize = (size: number): number => {
  return size * baseUnit;
}

// const ThemeProvider = createTheme(adaptV4Theme({
const RegwebTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 769,
      lg: 961,
      xl: 1200,
    }
  },
  palette: {
    primary: {
        main: Colors.ocean,
        light: Colors.oceanLight
    },
    secondary: {
        main: Colors.ruby,
        light: Colors.rubyLight,
    },
    info: {
        main: Colors.lemon,
        light: Colors.lemon,
    },
    background: {
      default: Colors.ash,
    },
  },
  // Use Styleguide's styling to override MUI forms
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#f7f7f7',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: Colors.realOcean,
            // eslint-disable-next-line max-len
            boxShadow: `0 0 0 inset rgba(${Colors.cyberspaceRGB}, 0.2), 0 1px 2px rgba(${Colors.cyberspaceRGB}, 0.2), 0 0 0 3px ${Colors.realOceanLight}`,
            '& > legend': {
              background: '#f7f7f7',
              visibility: 'visible',
              '& > span': {
                visibility: 'hidden',
              }
            }
          },
          '&.Mui-error': {
            backgroundColor: Colors.rubyExtraLight,
            color: Colors.cyberspace,
          }
        },
        input: {
          padding: '9px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: Colors.cyberspace,
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          component: 'div',
          color: Colors.cyberspace,
          fontFamily: fontFamilyBase,
          fontSize: '.88889rem',
          marginTop: 0,
          backgroundColor: Colors.concrete,
          padding: '.25rem .5rem',
          '&.MuiFormHelperText-contained': {
            margin: 0,
            borderBottomRightRadius: '.25rem',
            borderBottomLeftRadius: '.25rem',
          },
          '&.Mui-error': {
            backgroundColor: Colors.rubyLight,
            color: Colors.cyberspace,
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          borderBottomColor: Colors.granit,
          color: Colors.cyberspace,
        },
        head: {
          borderBottom: '2px solid',
          borderBottomColor: Colors.granit,
          color: Colors.cyberspace,
          fontFamily: 'HK Grotesk Semibold, sans-serif',
          paddingBottom: 7,
          textTransform: 'uppercase',
          paddingRight: '0',
          "&.MuiTableCell-alignCenter > div": {
            flexDirection: "column",
          },
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "$labelcolor"
          },
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: Colors.cyberspace,
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: Colors.ruby,
          }
        }
      }
    },
  }
});

/** Force some properties to be empty so that Styleguide css is used. */
RegwebTheme.typography = {
  fontFamily: fontFamilyBase,
  fontSize: sizeBase,
  htmlFontSize: sizeBase,
  fontWeightLight: 100,
  fontWeightRegular: 200,
  fontWeightMedium: 300,
  fontWeightBold: 400,
  h1: {},
  // h2 should deviate from styleguide according to design decision.
  h2: {
    fontFamily: 'HK Grotesk Semibold, sans serif',
    fontSize: '1.5rem',
    htmlFontSize: '1.5rem',
    paddingLeft: '0.5rem',
    marginBottom: '0.5rem',
    lineHeight: '1.75rem',
  },
  h3: {},
  h4: {},
  h5: RegwebTheme.typography.h5,
  h6: RegwebTheme.typography.h6,
  subtitle1: RegwebTheme.typography.subtitle1,
  subtitle2: RegwebTheme.typography.subtitle2,
  body1: {},
  body2: {},
  caption: RegwebTheme.typography.caption,
  button: RegwebTheme.typography.button,
  overline: RegwebTheme.typography.overline,
  pxToRem: RegwebTheme.typography.pxToRem,
}

export default RegwebTheme;
