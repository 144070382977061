import React, {FC, useContext, useEffect, useState} from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Popover from '@mui/material/Popover';
import Link from '@mui/material/Link';
import clsx from 'clsx';
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";

import { ReactComponent as Logo } from '../../images/internetstiftelsen_logo.svg';
import { ReactComponent as ArrowDown } from '@internetstiftelsen/styleguide/src/atoms/icon/arrow-down.svg';
import { ReactComponent as HamburgerIcon } from '@internetstiftelsen/styleguide/src/atoms/icon/hamburger.svg';

/** Context */
import { authContext } from '../../contexts/AuthContext';
import { languageContext } from '../../contexts/LanguageContext';

/** Presentation */
import Login from '../../routes/auth/Login';
import Logout from '../../routes/auth/Logout';
import ToggleLanguageBtn from '../Language/ToggleLanguageBtn';
import TranslatedText from '../Language/TranslatedText';
import SecondaryNav from './SecondaryNav';

import {Colors} from '../../utils/Consts';
import { menuLink, isCurrent, py0, px0} from '../../utils/Classes';
import {getDeskproLoginPath, getDeskproURL} from '../../utils/Environment';
import ToggleLanguageDrawer from "../Language/ToggleLanguageDrawer";
import ArrowMenuList from "./ArrowMenuList";

const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    backgroundColor: '#fff',
  },
  burgerMenu: {
    marginLeft: 'auto',
    outline: 'none',
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  root: {
    flexGrow: 1,
  },
  downArrow: {
    width: '1.1rem',
    height: '1.1rem',
    marginLeft: '0.5rem',
    marginTop: '.4rem',
  },
  drawerList: {
    width: 'auto',
  },
  navbar: {
    color: Colors.cyberspace,
    zIndex: theme.zIndex.drawer + 1,
    '& .MuiButton-text': {
      backgroundColor: "unset",
    },
  },
  navBrand: {
    marginRight: theme.spacing(2),
    width: '180px',
  },
  title: {
    flexGrow: 1,
  },
  btnGroupRight: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  menuBtn: {
    padding: '.125rem 0',
  },
  menuLink,
  isCurrent,
  py0,
  px0,
}));

type SupportLinkCallback = (event: React.MouseEvent<HTMLAnchorElement>) => void;

type SupportLink = {
  text: string;
  url: string;
  handleFunc?: SupportLinkCallback;
}

export interface ITopNav {
  navigationID: string;
}

const TopNav: FC<ITopNav> = (props: ITopNav) => {
  const {navigationID} = props;
  const { auth } = useContext(authContext);
  const lang = useContext(languageContext);
  const classes = useStyles();
  const [loginPopoverEl, setLoginPopoverEl] = useState<null | HTMLElement>(null);
  const loginPopoverVisible = Boolean(loginPopoverEl);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loginBtnText, setLoginBtnText] = useState('Sign in');
  const loginPopoverID = loginPopoverVisible ? 'toggle-login-popover' : undefined;
  const deskProURL = getDeskproURL();
  const deskProLoginURL = deskProURL + getDeskproLoginPath();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  useEffect(() => {
    const loggedIn: boolean = auth.username !== '' && auth.token !== '';
    setUserLoggedIn(loggedIn);

    const btnText = !loggedIn ? 'Sign in' : auth.userDisplayName;
    setLoginBtnText(btnText);
  }, [auth.username, auth.token, auth.userDisplayName]);

  const handleSupportLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, cb?: SupportLinkCallback): void => {
    if (cb) {
      event.preventDefault();
      cb(event)
    }
  };

  const handleLoggedInDeskproLink = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const linkElement = event.target as HTMLAnchorElement;
    const url = linkElement.href;
    if (auth.deskproToken) {
      const urlList = url.split("/");
      const path = urlList[urlList.length - 1];
      window.location.href = `${deskProLoginURL}?return=/${path}&jwt=${auth.deskproToken}`;
    } else {
      window.location.href = url;
    }
  };

  const supportLinks: SupportLink[] = [
    {
      text: lang.getTranslatedText('Guides'),
      url: `${deskProURL}/${lang.language}/guides`,
      handleFunc: handleLoggedInDeskproLink,
    },
    {
      text: lang.getTranslatedText('Knowledgebase'),
      url: `${deskProURL}/${lang.language}/kb`,
      handleFunc: handleLoggedInDeskproLink,
    },
    {
      text: lang.getTranslatedText('News'),
      url: `${deskProURL}/${lang.language}/news`,
      handleFunc: handleLoggedInDeskproLink,
    },
    {
      text: lang.getTranslatedText('Downloads'),
      url: `${deskProURL}/${lang.language}/downloads`,
      handleFunc: handleLoggedInDeskproLink,
    },
    {
      text: lang.getTranslatedText('Contact'),
      url: `${deskProURL}/${lang.language}/new-ticket`,
      handleFunc: handleLoggedInDeskproLink,
    }
  ];

  const handleLoginBtnClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (userLoggedIn) {
      setLoginPopoverEl(event.currentTarget);
      return;
    }

    setLoginModalOpen(true);
  };

  const handleLoginBtnClose = (): void => {
    setLoginPopoverEl(null);
  };

  const toggleDrawer = (open: boolean): void => {
    setDrawerOpen(open);
  };

  const burgerListItem = clsx({
    [classes.menuLink]: true,
    [classes.py0]: true,
    [classes.px0]: true,
  });

  const drawerMenuList = (): React.ReactElement => (
    <div
      className={classes.drawerList}
      role="presentation"
      onClick={(): void => toggleDrawer(false)}
    >
      <List>
        {supportLinks.map((link, _) => (
          <ListItem key={link.text.toLowerCase()}>
            <ListItemIcon />
            <Link className={`a-main-menu__list__link ${burgerListItem}`}
                  href={link.url}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>): void =>
                    handleSupportLinkClick(event, link.handleFunc)}>
              {link.text}
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon />
          <Link className={`a-main-menu__list__link ${burgerListItem}`} href="/apply">
            <TranslatedText textKey="Become registrar" />
          </Link>
        </ListItem>

        <ListItem>
          <ToggleLanguageDrawer />
        </ListItem>

        <ListItem onClick={(): void => {setLoginModalOpen(true)}}>
          {userLoggedIn ?
            <ArrowMenuList headerText={auth.registrarId}>
              <Logout />
            </ArrowMenuList> :
            <>
              <ListItemIcon />
              <Link className={`a-main-menu__list__link ${burgerListItem}`}
                    href="#"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => e.preventDefault()}>
              <TranslatedText textKey="Sign in" />
              </Link>
            </>
          }
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="sticky" elevation={0} className={`o-header ${classes.appBar}`}>

        {/** Navigation level 1 */}
        <Toolbar className={`wrapper ${classes.navbar}`} disableGutters={true}>
          <a className="o-header__logo-link" color="inherit" aria-label="home" href="/">
            <span className="o-header__logo">
              <Logo className="logotype" />
            </span>
            <span className="u-visuallyhidden">Internetstiftelsen</span>
          </a>

          <nav id={navigationID} className={`a-main-menu ${classes.btnGroupRight}`}>
            <div className={`a-main-menu__list`}>
                {/** Support links */}
                {supportLinks.map((link, index) => (
                  <Link className={`a-main-menu__list__link ${classes.menuLink}`}
                        key={`suppLink${index}`}
                        href={link.url}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>): void =>
                          handleSupportLinkClick(e, link.handleFunc)}
                  >
                    <span className="a-main-menu__list__text">{link.text}</span>
                  </Link>
                ))}

                {/** Apply link */}
                {!userLoggedIn && (
                  <a href="/apply" id="registrar" className={`a-main-menu__list__link ${classes.menuLink}
                    ${clsx((window.location.pathname === '/apply') &&
                    classes.isCurrent)}`}>
                    <TranslatedText textKey="Become registrar" />
                  </a>
                )}

                {/** Language */}
                <ToggleLanguageBtn />

                {/** Login */}
                <button className={`a-main-menu__list__link ${classes.menuLink} ${clsx(userLoggedIn && classes.isCurrent)}`}
                        id="nav-signin"
                        aria-describedby={loginPopoverID}
                        color="inherit"
                        onClick={handleLoginBtnClick}>
                  <TranslatedText textKey={loginBtnText} />
                  {userLoggedIn && <ArrowDown className={classes.downArrow} />}
                </button>
                <Popover
                  id={loginPopoverID}
                  open={loginPopoverVisible}
                  anchorEl={loginPopoverEl}
                  onClose={handleLoginBtnClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {userLoggedIn && <Logout />}
                </Popover>
            </div>
          </nav>

          {/** Hamburger menu for mobile devices */}
          <React.Fragment>
            <button className={`a-main-menu__list__link ${classes.burgerMenu}`}
                    onClick={(): void => toggleDrawer(true)}
                    type="button">
              <span className={"iis-a-main-menu__list__text"}>{lang.getTranslatedText('Menu')}</span>
              <div className="icon-hamburger"><HamburgerIcon /></div>
            </button>
            <Drawer anchor="top" open={drawerOpen} onClose={(): void => toggleDrawer(false)}>
              {drawerMenuList()}
            </Drawer>
          </React.Fragment>


        </Toolbar>

        {/** Navigation level 2 */}
        {userLoggedIn && <SecondaryNav />}
      </AppBar>

      <Login onLogin={(): void => setLoginModalOpen(false)} close={!loginModalOpen}/>
    </>
  );
};

export default TopNav;