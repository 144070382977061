import {useState} from 'react';
import LanguageEn from '../../translations/en/language.json';
import LanguageSv from '../../translations/sv/language.json';

/** Custom types */
// eslint-disable-next-line no-unused-vars
import { TranslationKey, LanguageTexts } from '../../custom-types';

type LanguageStatus = {
  language: string;
  setLanguageStatus: (langauge: string) => void;
  languageTranslations: LanguageTexts;
}

const useLanguageHandler = (initialState: string): LanguageStatus => {
  const availableLanguages = ['en', 'sv'];
  const languageSources: LanguageTexts = {
    'sv': LanguageSv,
    'en': LanguageEn,
  };
  const [language, setLanguage] = useState(initialState);

  const [languageTranslations] = useState<LanguageTexts>(() => {
    const translations: LanguageTexts = {};

    Object.entries(languageSources).forEach(([lang, langData]) => {
      for (const k in langData) {
        if (typeof k === 'string' && typeof langData[k] === 'string') {
          const langTranslation: TranslationKey = translations[k] || {};
          langTranslation[lang] = langData[k];
          translations[k] = langTranslation;
        }
      }
    });

    return translations;
  });

  const setLanguageStatus = (newLanguage: string): void => {

    if (availableLanguages.includes(newLanguage)) {
      setLanguage(newLanguage);
      document.documentElement.lang = newLanguage;
      window.localStorage.setItem('userlang', JSON.stringify(newLanguage));
    }
  };

  return {
    language,
    setLanguageStatus,
    languageTranslations,
  };
};

export default useLanguageHandler;
