import { OutlinedTextFieldProps } from '@mui/material/TextField';
import React, { FC } from 'react';

import TranslatedText from '../Language/TranslatedText';
import {ReactComponent as ShowButton} from '@internetstiftelsen/styleguide/src/atoms/icon/read.svg';
import IISInput from './IISInput';
import { InputAdornment } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IPasswordInputProps extends Omit<OutlinedTextFieldProps, 'variant'> {
    label: string;
    value: string;
    inputId: string;
}

const useStyles = makeStyles(createStyles({
    inputButtonStyleguide: {
        border: 0,
        borderRadius: 0,
        background: 'transparent',
        cursor: 'pointer',
    },
    iconStyleFixes: {
        transform: 'translateY(-0.6rem) translateX(0rem) !important',
    }
}))

export const PasswordInput: FC<IPasswordInputProps> = (props: IPasswordInputProps) => {
    const classes = useStyles();

    const handleClick = (): void => {
        const input = document.getElementById(props.inputId) as HTMLInputElement;
        if (input) {
            input.type = input.type === 'password' ? 'text' : 'password';
        }
    }

    const visibilityButton = (
        <InputAdornment position="end">
            <button type="button"
                    className={`${classes.inputButtonStyleguide} input-icon`}
                    onClick={handleClick}>
                <ShowButton className={`icon ${classes.iconStyleFixes}`}/>
                <span className="u-visuallyhidden">
                    <TranslatedText textKey="Show password" />
                </span>
            </button>
        </InputAdornment>
    )

    return (
        <div className="field-group">
            <IISInput type="password"
                   placeholder=""
                   autoComplete="off"
                   id={props.inputId}
                   value={props.value}
                   onChange={props.onChange}
                   name={props.name}
                   label={props.label}
                   helperText={props.helperText}
                   error={props.error}
                   disabled={props.disabled}
                   endIcon={visibilityButton}
                   autoFocus={props.autoFocus}
                   required={props.required}
                   multiline={props.multiline} />
        </div>
    )
}

export default PasswordInput;
