import {useContext, useEffect, useState} from 'react';
import {languageContext} from "../../contexts/LanguageContext";
import {getDeskproURL} from "../Environment";
import {Html5Entities} from "html-entities";
import SplashPrice from "../../images/news/priceincrease.jpg";
import SplashGeneral from "../../images/news/general.jpg";
import SplashRelease from "../../images/news/release_announcements.jpg";
import SplashSystem from "../../images/news/system_main.jpg";
import SplashBlog from "../../images/news/blogposts.png";
import SplashEvents from "../../images/news/events.png";
import SplashNewsletter from "../../images/news/newsletters.png";
import SplashTraining from "../../images/news/training.png";
import {getDeskproNews} from "../Requests";
import {DeskproNews} from "../../custom-types/Deskpro";

interface INewsArticle {
  imageSrc?: string;
  publicationDate: number;
  title: string;
  description: string;
  link: string;
}

type Articles = {
  loadingNews: boolean;
  newsArticles: INewsArticle[];
  newsError: string;
}

const useNewsArticles = (onlyPublicNews: boolean): Articles => {
  const lang = useContext(languageContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [newsArticles, setNewsArticles] = useState<INewsArticle[]>([]);

  const deskproURL = getDeskproURL();

  /**
   * API content response contains html tags which we do not need.
   * This is not a proper sanitize, only making a simple removal of tags.
   * Will also decode text to handle i.e. å,ä,ö (&ouml;)
   */
  const stripTags = (text: string, htmlEntities: Html5Entities): string => {
    const t = text.replace(/<\/?[^>]+(>|$)/g, "");
    return htmlEntities.decode(t);
  };

  /**
   *
   * @param slug: category slug
   * @param title: optional - article title
   */
  const imgSrcForCategory = (slug: string, title?: string): string => {
    // Note special case.
    if (title?.toLowerCase().includes('price')) {
      return SplashPrice;
    }

    if (slug.includes('general')) {
      return SplashGeneral;
    }

    if (slug.includes('release')) {
      return SplashRelease;
    }

    if (slug.includes('system')) {
      return SplashSystem;
    }

    if (slug.includes('blog')) {
      return SplashBlog;
    }

    if (slug.includes('event')) {
      return SplashEvents;
    }

    if (slug.includes('training')) {
      return SplashTraining;
    }

    if (slug.includes('news')) {
      return SplashNewsletter;
    }

    return SplashGeneral;
  };

  const parseNewsData = (data: DeskproNews): INewsArticle[] => {
    const newsEntries = Object.values(data.news);
    const htmlEntities = new Html5Entities();

    const publishedItems = newsEntries.filter((article) => {
      return article.status === 'published';
    }).sort((articleA, articleB) => {
      return articleB.date_published_ts_ms - articleA.date_published_ts_ms;
    }).slice(0, onlyPublicNews ? 2 : 3);

    return publishedItems.map((article) => {
      const formattedArticle: INewsArticle = {
        description: stripTags(article.content, htmlEntities),
        title: article.title,
        publicationDate: article.date_published_ts_ms,
        link: `${deskproURL}/${lang.language}/news/posts/${article.slug}`,
        imageSrc: imgSrcForCategory(article.category.slug, article.title),
      };

      return formattedArticle;
    });
  };

  // Load public news
  useEffect(() => {
    setLoading(true);
    getDeskproNews(!onlyPublicNews).then((resp) => {
      resp.match({
        Ok: (okData) => {
          const formattedNews: INewsArticle[] = parseNewsData(okData.value);
          setNewsArticles(formattedNews);
        },
        Err: () => setError(lang.getTranslatedText('Could not get news articles'))
      })
    }).finally(() => {
      setLoading(false);
    });
  }, [deskproURL, lang.language]);

  return {
    loadingNews: loading,
    newsError: error,
    newsArticles: newsArticles
  }
};

export default useNewsArticles;