import { User } from "../custom-types/User";

const DEFAULT_USER_AUTH = {
  username: '',
  userDisplayName: '',
  registrarId: '',
  token: '',
  deskproToken: '',
  role: '',
};


const DEFAULT_LANGUAGE = 'en';

const Colors = {
  ruby: "#ff4069",
  rubyLight: "#ff9fb4",
  rubyExtraLight: "#fff1f4", // rubyLight lighten 16%
  peacock: "#c27fec",
  peacockLight: "#e0bff5",
  jade: "#55c7b4",
  jadeLight: "#aae3d9",
  sandstone: "#f99963",
  sandstoneLight: "#fcccb1",
  lemon: "#ffce2e",
  lemonLight: "#ffe696",
  ocean: "#0477ce",
  oceanLight: "#a7d8fd",
  realOcean: '#50b2fc',
  realOceanLight: '#a7d8fd',
  cyberspace: "#1f2a36",
  cyberspaceRGB: "31, 42, 54",
  granit: "#8E9297",
  concrete: "#d8d8d8",
  ash: "#ededed",
}

const EMPTY_USER: User = {
  registrar_id: "",
  role: "",
  full_name: "",
  email: "",
  state: 0,
  tlds: [],
  tld_lid: [],
  certificates: [],
  ip_whitelist: [],
  totp_active: false,
  created_at: "",
  updated_at: "",
  deleted_at: "",
  username: "",
  role_privileges: []
}

// eslint-disable-next-line max-len
const PASSWORD_RESET_TEXT = 'An email with instructions has been sent to your email address.'

export { DEFAULT_USER_AUTH, DEFAULT_LANGUAGE, Colors, EMPTY_USER, PASSWORD_RESET_TEXT };
