import { Result, Ok, Err } from './Result';

export const getEnvironmentVariable = (name: string): Result<string, string> => {
  const metaTagElem = document.querySelector(`meta[name="${name}"]`);
  const metaTagElemContent = metaTagElem ? metaTagElem.getAttribute('content') : null;
  return metaTagElemContent ? new Ok(metaTagElemContent) : new Err('');
}

export const getApiURL = (): string => {
  return getEnvironmentVariable('env-api-url').match({
    'Ok': (ok) => ok.value,
    'Err': (_) => {
      const envApiUrl = process.env.REACT_APP_API_URL;
      if (typeof envApiUrl !== 'undefined') return envApiUrl;
      return '';
    }
  });
};

export const getDeskproURL = (): string => {
  return getEnvironmentVariable('env-deskpro-url').match({
    'Ok': (ok) => ok.value,
    'Err': (_) => {
      const deskproURL = process.env.REACT_APP_DESKPRO_URL;
      if (typeof deskproURL !== 'undefined') return deskproURL;
      return '';
    }
  });
};

export const getDeskproLoginPath = (): string => {
  return getEnvironmentVariable('env-deskpro-login-path').match({
    'Ok': (ok) => ok.value,
    'Err': (_) => {
      const deskproURL = process.env.REACT_APP_DESKPRO_LOGIN_PATH;
      if (typeof deskproURL !== 'undefined') return deskproURL;
      return '/login/authenticate-callback/3';
    }
  });
};

export const getLogoUrl = (): string => {
  return getEnvironmentVariable('env-logo-url').match({
    'Ok': (ok) => ok.value,
    'Err': (_) => {
      const logoURL = process.env.REACT_APP_S3_LOGO_URL;
      if (typeof logoURL !== 'undefined') return logoURL;
      return '';
    }
  });
};

export const getDibsUrl = (): string => {
  const dibsScriptPath = '/v1/checkout.js?v=1';
  return getEnvironmentVariable('env-dibs-url').match({
    'Ok': (ok) => `${ok.value}${dibsScriptPath}`,
    'Err': (_) => {
      const method = process.env.REACT_APP_DIBS_URL;
      if (typeof method !== 'undefined') return method;
      return `https://test.checkout.dibspayment.eu${dibsScriptPath}`;
    }
  });
};

export const getDibsCheckoutKey = (): string => {
  return getEnvironmentVariable('env-dibs-checkout-key').match({
    'Ok': (ok) => ok.value,
    'Err': (_) => {
      const method = process.env.REACT_APP_DIBS_CHECKOUT_KEY;
      if (typeof method !== 'undefined') return method;
      return "00000000000000000000000009000000";
    }
  });
};
