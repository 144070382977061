import React, { FC } from 'react';
import { Redirect, Route, RouteProps} from 'react-router-dom';


export interface IPrivateRouterProps extends RouteProps {
    redirectPath: string;
    isAuthenticated: boolean;
    component: FC;
}

export const PrivateRoute: FC<IPrivateRouterProps> = (props: IPrivateRouterProps) => {
    const {component: Component, isAuthenticated, redirectPath, ...rest} = props;

    return (
        <Route {...rest} render={(): JSX.Element => (
            isAuthenticated ?
            (<Component  />) :
            (<Redirect to={{pathname: redirectPath}} />)
        )} />
    )
}

export default PrivateRoute;
